import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quizActions from "../../redux/reducers/quizzes/quizzesAction";
import * as soosActions from "../../redux/reducers/soos/soosAction";
import { QuizzesList } from "../../components/quizzes/QuizList.component";
import { useStyles } from "../../uiCore/styles";
import ImgWelcome from "../../assets/images/img-welcome.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { SoosList } from "../../components/soos/SoosList";
import { getCartEntitiesUtil } from "../../config/constants";

const UserLandingPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [quizzesState, setQuizzesState] = useState<any>([]);
  const [soosesState, setSoosesState] = useState<any>([]);
  const { user, quizzes, userQuizzes, sooses } = useSelector((state: any) => ({
    user: state.authentication.user,
    quizzes: state.quizzes.entities,
    userQuizzes: state.quizzes.userQuizzesEntities,
    sooses: state.sooses.entities,
  }));

  useEffect(() => {
    dispatch(quizActions.fetchQuizzes());
    if (user) {
      dispatch(quizActions.fetchUserQuizzes());
    }
    dispatch(soosActions.fetchSooses());
  }, [dispatch, user]);

  useEffect(() => {
    if (quizzes?.length) {
      let allQuizzes = quizzes.map((item: any) => {
        return { ...item, addToCart: false };
      });
      setQuizzesState([...allQuizzes]);
    }
  }, [quizzes]);

  useEffect(() => {
    if (sooses?.length) {
      let allSooses = sooses.map((item: any) => {
        return { ...item, addToCart: false };
      });
      setSoosesState([...allSooses]);
    }
  }, [sooses]);

  useEffect(() => {
    let cartEntities = [];
    cartEntities = getCartEntitiesUtil(quizzesState, soosesState);
    dispatch(quizActions.cartItems(cartEntities));
  }, [dispatch, quizzesState, soosesState]);

  return (
    <div className="landing-wrapper">
      <section className={`${classes.section} ${classes.landerPageText}`}>
        <div className="row align-items-start mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              <div className="landingPageHeading">
                {/* Welcome to CCFP Ready
                <br />
                (PURCHASE PAGE) */}
              </div>
              <h3>SAMPs</h3>
              <ul>
                <li>
                  Once you have purchased a quiz or package of quizzes you will
                  have one time access to each quiz
                </li>
                <li>
                  Each quiz contains 20 SAMP- style questions covering the 105
                  topics for the CCFP exam
                </li>
                <li>
                  Within 24 hours you will receive personalized feedback from
                  qualified family physicians working in Canada and a percentile
                  score
                </li>
                <li>
                  We anticipate that you will need 1.5-2 hours to complete each
                  Quiz but you will have no set time limit on these practise
                  quizzes
                </li>
                <li>
                  Clicking on a quiz below will not immediatley start the quiz-
                  you will be taken to another access page first
                </li>
              </ul>
              <h3>SOOs</h3>
              <ul>
                {/* <li>
                  Please only purchase one SOO as the content will be the same/
                  similar on all days
                </li> */}
                <li>
                  Please note that each weekend will feature unique SOO content
                </li>

                <li>
                  With purchase you will receive 3 virtual practice SOOs that
                  last 15 minutes each with a simulated patient
                </li>
                <li>
                  Each 15 minute station will cover 2 issues, similarly to the
                  real CCFP exam
                </li>
                <li>
                  You will receive personalized feedback scores and marking
                  sheets within 24 hours of completion of your virtual SOO
                </li>
                <li>
                  The entire session will take between 90-120 minutes to
                  complete
                </li>
                <li>
                  Shortly after purchase you will receive an email with more
                  details on how to log-in to your virtual SOO, exact log-in
                  times, etc
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`img-wrap right`}>
              <img src={ImgWelcome} alt="Welcome Img" />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">PLEASE NOTE THAT ALL TIMES ARE <br />BASED ON EASTERN STANDARD TIME</div> 
        </div> */}
      </section>
      <section className="row">
        <div className="col-lg-6 px-0">
          <section className={`${classes.section}`} id="userQuizzes">
            <Container>
              <Col>
                <h2 className="p-4">SAMPs:</h2>
                <QuizzesList
                  userQuizzes={userQuizzes}
                  quizzes={quizzesState}
                  setQuizzes={setQuizzesState}
                />
              </Col>
            </Container>
          </section>
        </div>
        <div className="col-lg-6 px-0">
          <div className={`${classes.mobileReposive} `}>
            <div className="d-flex">
              PLEASE NOTE THAT ALL TIMES ARE BASED ON EASTERN STANDARD TIME
            </div>
            <div className="d-flex">
              PLEASE NOTE THAT EACH WEEKEND WILL FEATURE UNIQUE SOO CONTENT.
            </div>
          </div>
          <section className={`${classes.section}`} id="userSoos">
            <Container>
              <Col className={`${classes.margin}`}>
                <h2 className="p-4">SOOs:</h2>
                <SoosList
                  sooses={soosesState}
                  setSoosesState={setSoosesState}
                />
              </Col>
            </Container>
          </section>
        </div>
      </section>
    </div>
  );
};

export default UserLandingPage;
