import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import Logo from "../../../assets/images/logo-light.svg";
import Readylogo from "../../../assets/images/ccfpready-logo.jpg"
import IconFacebook from "../../../assets/images/icon-social-facebook.svg";
// import IconTwitter from "../../../assets/images/icon-social-twitter.svg";
// import IconLinkedin from "../../../assets/images/icon-social-linkedin.svg";
import IconInstagram from "../../../assets/images/icon-social-instagram.png";

export const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className="d-lg-flex mb-lg-0 mb-md-3">
        <div className={classes.logo}>
          <img src={Readylogo} alt="Logo"></img>
          <p>@2021 Copyrights CCFP</p>
        </div>
        <ul className={`${classes.footer_links} list-unstyled mt-md-3 mt-lg-0`}>
          <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
          {/* <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li> */}
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
      <ul className={`${classes.footer_social} list-unstyled`}>
        <li>
          <a target="_blank" href="https://www.facebook.com/CCFPready" rel="noreferrer">
            <img src={IconFacebook} alt="Facebook" title="Facebook" />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/ccfpready/?utm_medium=copy_link" rel="noreferrer">
            <img src={IconInstagram} alt="Instagram" title="Instagram" />
          </a>
        </li>
      </ul>
    </footer>
  );
};
