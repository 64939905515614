import * as requestFromServer from "./checkoutCrud";
import { checkoutSlice, callTypes } from "./checkoutSlice";
import toast from "react-hot-toast";

const { actions } = checkoutSlice;

export const checkoutVerifyPayment = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendVerifyCheckoutPayment(data)
    .then((response) => {
      dispatch(actions.checkoutVerifyPaymentState(response.data.data));
    })
    .catch((error: any) => {
      error.clientMessage = "Can't find users";
      toast.error(error?.response?.data?.message);
      dispatch(
        actions.catchError({
          error: error.response.data.message,
          callType: callTypes.list,
        })
      );
    });
};

export const addUserQuizCheckout = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.addUserQuiz(data);
};
