import React from "react";
import { useStyles } from "./styles";

interface FinputProps {
  label?: String;
  display?: String;
  placeholder?: String;
  className?: String;
  type?: String;
  inputSize?: String;
  id?: String;
  name?: String;
  value?: any;
  min?: String;
  max?: String;
  tabIndex?: any;
  onChange?: any;
  prefix?: any;
  prefixAction?: any;
  postfix?: any;
  postfixAction?: any;
  validations?: any;
  register?: any;
  required?: any;
  error?: any;
  disabled?: boolean;
  readonly?: boolean;
  autoComplete?: String;
}

export const InputText = ({
  label,
  placeholder = "",
  type = "text",
  display = "block",
  className = "",
  inputSize = "",
  name = "",
  id = "",
  value = "",
  min = "0",
  max = "10",
  tabIndex,
  prefix,
  prefixAction = () => {
    console.log("prefix action");
  },
  postfix,
  postfixAction = () => {
    console.log("postfix action");
  },
  onChange = () => {},
  validations,
  register,
  required,
  error,
  disabled = false,
  readonly = false,
  autoComplete = "off",
  ...otherProps
}: FinputProps) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={`input-group ${classes.inputDefautStyle} ${
          display ? `d-${display}` : ""
        } ${className} ${inputSize} `}
      >
        {label && (
          <label className="input-label">
            {label}{" "}
            {validations?.required && <span className="required"></span>}
          </label>
        )}
        <div className="input-wrap">
          <input
            className={`form-control`}
            name={name}
            placeholder={`${placeholder}`}
            {...(register
              ? register(name, { ...validations })
              : { value: value, onChange: onChange })}
            type={type}
            disabled={disabled}
            readOnly={readonly}
            tabIndex={tabIndex}
            {...otherProps}
          ></input>
        </div>
        {error && (
          <p className={`${error ? "input-validation-error" : ""}`}>
            {error ? error : undefined}
          </p>
        )}
      </div>
    </>
  );
};
