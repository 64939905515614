import { createSlice } from "@reduxjs/toolkit";

const initialSoosesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  soosEntities: null,
  singleSoosEntities: null,
  cartEntities: null,
  lastError: null,
  navigate: false,
  error: null,
  loading: false,
  toast: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const soosesSlice = createSlice({
  name: "sooses",
  initialState: initialSoosesState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.loading = false;
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findSooses
    soosesFetched: (state, action) => {
      state.error = null;
      state.loading = true;
      state.listLoading = false;
      state.entities = action.payload.entities;
    },
  },
});
