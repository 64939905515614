import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../../uiCore/theme";

export const useStyles = createUseStyles({
  header: {
    position: "relative",
    zIndex: 1000,
    padding: [32, 0],
    "&:after": {
      content: '""',
      background: Colors.skyBlue,
      minWidth: "30%",
      height: "100%",
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 100,
    },
    "& .navbar ": {
      position: "relative",
      zIndex: 1000,
      background: "none",
    },
    "@media screen and (max-width: 991px)": {
      "& .navbar-toggler": {
        outline: "none !important",
        border: "0 !important",
        boxShadow: "none !important",
        "& .navbar-toggler-icon": {
          width: 50,
        },
      },
      "& .navbar-collapse": {
        position: "absolute",
        padding: [10, 20],
        right: 0,
        top: "100%",
        background: "#e2f2ff",
        width: "100%",
        textAlign: "right",
        boxShadow: Global.btnShadow,
        "& a": {
          width: "100%",
          padding: 0,
          margin: [20, 0],
        },
        "& button": {
          marginLeft: "auto !important",
          marginRight: "0 !important",
          marginBottom: 20,
        },
        "& .dropdown": {
          marginLeft: "auto !important",
        },
      },
    },
  },
  logo: {
    maxWidth: 150,
    "& img": {
      width: 200,
    },
  },
  link: {
    color: `${Colors.themePrimayColor}`,
    fontSize: 20,
    paddingRight: "50px",
    textDecoration: "none",
    position: "relative",
    "&:last-child": {
      paddingRight: 0,
    },
    "& img": {
      width: 50,
      marginLeft: 30,
    },
    "& .counter": {
      borderRadius: 100,
      background: "#FF5A5A",
      position: "absolute",
      top: -5,
      right: -5,
      minWidth: 18,
      height: 18,
      color: "white",
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  userDropdown: {
    marginLeft: "7vw",
    "& button": {
      padding: "10px 16px !important",
      fontSize: 20,
      fontWeight: 500,
      border: [3, "solid", Colors.themePrimayColor],
      boxShadow: Global.btnShadow,
      textAlign: "left",
      whiteSpace: "nowrap",
      transition: Global.transition,
      background: Colors.btnPrimaryColor,
      color: "white",
      borderRadius: 0,
      "& img": {
        width: 25,
      },
      "& .welcome-text": {
        minWidth: 175,
        marginLeft: "1rem",
      },
    },
    "& .dropdown-menu": {
      maxWidth: 300,
      width: "100%",
      borderRadius: 0,
      "& .dropdown-item": {
        display: "flex",
        color: "red",
        fontSize: 20,
        padding: [10, 20],
        "& .item-icon": {
          marginRight: 10,
          color: "red",
          "& svg": {
            verticalAlign: -3,
          },
        },
      },
    },
  },
});
