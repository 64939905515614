//validations
// eslint-disable-next-line
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// eslint-disable-next-line
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z]).{8,20}$/;

//Messages
export const AN_ERROR_OCCURED = "An error occured please try again later.";
export const INVALID_EMAIL = "Please enter a valid email address.";
export const INVALID_PASSWORD = "Please enter a valid password";
export const CONFIRM_PASSWORD = "Passwords does not match";
export const INVALID_PASSWORD_REGEX =
  "Password must be at least eight characters long, Contain letters and numbers";
export const INVALID_PHONE = "Please enter a valid phone number";
export const INVALID_NAME = "Please enter your";
export const REQUIRED_FIELD = "is required.";
export const EMAIL_SENT = "Email sent successfully.";
export const EMAIL_VERIFIED = "Email verified.";
export const INVALID_CODE = "Invalid code or the code has expired.";
export const INVALID_LINK = "Invalid link or the link has expired.";
export const MIN_LENGTH = (length) => {
  return `Minimum ${length} symbols`;
};
export const MAX_LENGTH = (length) => {
  return `Maximum ${length} symbols`;
};

/// ecseba required field validation
