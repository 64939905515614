import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { user } = useSelector((state) => ({
    user: state.authentication,
  }));

  const renderRedirect = (props) => {
    if (!user) {
      return (
        <Redirect
          to={{
            pathname: "/",
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={renderRedirect} />;
};

export default PrivateRoute;
