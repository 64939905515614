export enum Global {
  fontPrimary = "'Reem Kufi', sans-serif;",
  fontSecondary = "'Montserrat', sans-serif",
  btnShadow = "3px 5px 6px #f0f0f0",
  cardShadow = "3px 5px 6px #f0f0f0",
  transition = ".15s ease",
}

export enum Colors {
  themePrimayColor = "#008BBE",
  textPrimaryColor = "#404040",
  placeholderColor = "#858585",
  btnPrimaryColor = "#008BBE",
  btnSecondaryColor = "#F8F7F7",
  grayShadeOne = "#F8F7F7",
  grayShadeTwo = "#F1F2F4",
  grayShadeThree = "#F0F0F0",
  skyBlue = "#E2F2FF",
}
