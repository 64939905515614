import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { practiceSamp } from "../../redux/reducers/quizzes/quizzesAction";
import { PRACTICE_SAMP_DATA } from "./practice_samp_data";
import { Button, QuizQuestionText } from "..";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

const pracQuizInitVal = {
  statement: "",
  answer: "",
};

const PracticeStatementsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { handleSubmit } = useForm({ defaultValues: pracQuizInitVal });
  const [answerVal, setAnswerVal] = useState<any>("");
  const [answers, setAnswers] = useState<any>([]);

  useEffect(() => {
    if (PRACTICE_SAMP_DATA && PRACTICE_SAMP_DATA?.length) {
      setAnswers([...PRACTICE_SAMP_DATA]);
    }
  }, []);

  const submitPracticeQuiz = (data: any) => {
    if (data) {
      dispatch(practiceSamp([...answers]));
      toast.success("Your Practice SAMP is submitted");
      setAnswerVal("");
      history.push("/quiz-preview");
    }
  };

  return (
    <Container className="mb-lg-5">
      <form autoComplete="false" onSubmit={handleSubmit(submitPracticeQuiz)}>
        <QuizQuestionText
          questions={PRACTICE_SAMP_DATA}
          answers={answers}
          setAnswers={setAnswers}
          answerVal={answerVal}
          setAnswerVal={setAnswerVal}
        />
        <Button
          type="submit"
          variant="primary"
          text="Submit"
          className={"text-center"}
        />
      </form>
    </Container>
  );
};
export default PracticeStatementsPage;
