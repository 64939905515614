let i = 0;

export const PRACTICE_SAMP_DATA = [
  {
    _id: `pracStat${i++}`,
    statement:
      "At which Left ventricular ejection fraction would you consider referral for an ICD? List one.  ",
    answer: "",
    correct_answers: "LVEF < 35%  ",
  },
  {
    _id: `pracStat${i++}`,
    statement:
      "Based on the type of heart failure that this patient has, list 3 medications that would have a mortality benefit.",
    answer: "",
    correct_answers: "ARNI/ ACEi/ ARB, MRA, Beta blockers, SGLT-2 inhibitors",
  },
  {
    _id: `pracStat${i++}`,
    statement:
      "What lifestyle changes/ interventions should be considered in patients with heart failure? List 4.",
    answer: "",
    correct_answers:
      "Smoking cessation, Sodium restriction, Fluid restriction, Management of risk factors (ex. Blood pressure control), Exercise, Vaccinations (influenza and pneumococcal)",
  },
];
