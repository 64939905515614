import axios from "axios";

export const GET_SOOSES_URL = "soos";
export const CHECK_SOOS_AVAILABILITY_URL = "soos/check/available";

export function getAllSooses() {
  return axios.get(`${GET_SOOSES_URL}?limit=100&sortBy=startDateTime:asc`);
}

export function soosCheckAvailability(id: any) {
  return axios.get(`${CHECK_SOOS_AVAILABILITY_URL}/${id}`);
}
