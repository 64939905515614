import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as checkoutActions from "../../redux/reducers/checkout/checkoutAction";
import * as authAction from "../../redux/reducers/auth/authAction";
import { Card } from "react-bootstrap";
import IconSuccess from "../../assets/images/icon-message-success.svg";
import IconDeclined from "../../assets/images/icon-message-declined.svg";
import { useStyles } from "./style";
import { Button } from "../../components";
import { Storage } from "react-jhipster";
import { TOKEN_KEY } from "../../config/constants";

export const PaymentMessages = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch<any>();
  const DELAY = 5000;
  const [isShow,setIsShow] = useState(false);
  let paymentIds = window.location.href.split("=");

  useEffect(() => {
    setIsShow(false);
    setIsShow(true);
    if (paymentIds) {
      dispatch(
        checkoutActions.checkoutVerifyPayment({
          sessionId: paymentIds[1]?.split("&")[0],
          userId: paymentIds[2],
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  function delay(ms: any){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

  return isShow && location.pathname === "/checkout/success" ? (
    <Card className={classes.cardRedirectMessages}>
      <div className="card-icon">
        <img src={IconSuccess} alt="Icon"></img>
      </div>
      <h1>Thank you! your payment was successfull. </h1>
      <p>Click below to proceed</p>
      <Button
        text={"Proceed"}
        align="center"
        variant={"primary"}
        onClick={() => {
          if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
            dispatch(authAction.whoAmI()).then(() => {
              history.push("/welcome");
            });
          }
        }}
      />
    </Card>
  ) : isShow && location.pathname === "/checkout/cancel" ? (
    <Card className={classes.cardRedirectMessages}>
      <div className="card-icon">
        <img src={IconDeclined} alt="Icon"></img>
      </div>
      <h1>Oops! it seems to be an issue with your payment. </h1>
      <p>Click below to login and try again.</p>
      <Button
        text={"Try Again"}
        align="center"
        variant={"secondary"}
        onClick={() => {
          if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
            dispatch(authAction.whoAmI()).then(() => {
              history.push("/welcome");
            });
          }
        }}
      />
    </Card>
  ) : null;
};
