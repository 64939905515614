import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  buttonDefautStyle: {
    padding: [15, 16],
    fontSize: 20,
    fontWeight: 500,
    border: [3, "solid", Colors.themePrimayColor],
    boxShadow: Global.btnShadow,
    textAlign: "left",
    display: "flex",
    whiteSpace: "nowrap",
    transition: Global.transition,
    "& .spinner-grow": {
      width: "1.35rem",
      height: "1.35rem",
      "& .sr-only": {
        opacity: 0,
      },
    },
    "& .btn-text": {
      minWidth: 125,
      width: "100%",
    },
    "& .prefix": {
      marginRight: 10,
      "& img": {
        width: 24,
        verticalAlign: -4,
      },
    },
    "& .postfix": {
      float: "right",
      marginLeft: 50,
    },

    "&:not(disabled)&:focus, &:hover": {
      boxShadow: `0 0 15px rgba(0,0,0,.5)`,
    },
    "&:disabled": {
      opacity: 0.5,
      boxShadow: "none",
      cursor: "not-allowed",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      boxShadow: "none !important",
    },
  },
  buttonPrimary: {
    background: Colors.btnPrimaryColor,
    color: "white",
    "& .spinner-grow": {
      margin: 0,
      background: "rgba(255, 255, 255, .5)",
    },
  },
  buttonSecondary: {
    background: Colors.btnSecondaryColor,
    color: "#000000 !important",
    "& .spinner-grow": {
      margin: 0,
      background: "rgba(0, 139, 139, .5)",
    },
  },
});
