import React from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect, useLocation } from "react-router-dom";
import PrivateRoute from "./config/private-route";
import PublicRoute from "./config/public-route";

// PUBLIC IMPORTS
import LoginPage from "./pages/auth/LoginPage";
import RegisterLandingPage from "./pages/auth/register/RegisterLanding";
import CartPage from "./pages/cart/CartPage";
import ContactPage from "./pages/contact/ContactPage";
import { PageNotFound } from "./pages/response/404";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import QuizPreviewPage from "./pages/quiz/QuizPreviewPage";
import QuizPracticePreviewPage from "./pages/quiz/QuizPracticePreviewPage";

// PRIVATE IMPORTS
import LandingPage from "./pages/landing/LandingPage";
import UserLandingPage from "./pages/landing/UserLandingPage";
import { PaymentMessages } from "./pages/response/PaymentMessages";
import CompleteQuizPage from "./pages/quiz/CompleteQuizPage";
import QuizPage from "./pages/quiz/QuizPage";
import StartQuizPage from "./pages/quiz/StartQuizPage";
import AboutPage from "./pages/about/AboutPage";
// import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/terms-and-conditions/TermsAndConditionsPage";
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";
import detailBlogs from "./components/blogs/detailBlogs";
import { Blogs } from "./components/blogs/blogs";
import DetailBlogs from "./components/blogs/detailBlogs";

const BaseRoutes = () => {
  const { user } = useSelector((state: any) => ({
    loading: state.authentication.loading,
    user: state.authentication,
  }));

  return (
    <Switch>
      {/* PRIVATE */}
      <PrivateRoute
        isAuthenticated={user}
        exact
        path="/welcome"
        component={UserLandingPage}
      />
      <PrivateRoute
        isAuthenticated={user}
        exact
        path="/quiz/start/:id"
        component={StartQuizPage}
      />
      <PrivateRoute
        isAuthenticated={user}
        exact
        path="/quiz/completed/:id"
        component={CompleteQuizPage}
      />
      <PrivateRoute
        isAuthenticated={user}
        exact
        path="/quiz/:id/:pageNumber"
        component={QuizPage}
      />

      {/* PUBLIC */}
      <PublicRoute exact path="/" component={LandingPage} />
      <PublicRoute exact path="/blogs" component={Blogs} />
      <PublicRoute exact path="/blog/:title" component={DetailBlogs} />
      <PublicRoute exact path="/about" component={AboutPage} />
      <PublicRoute exact path="/contact" component={ContactPage} />
      {/* <PublicRoute exact path="/privacy-policy" component={PrivacyPolicyPage} /> */}
      <PublicRoute
        exact
        path="/terms-and-conditions"
        component={TermsAndConditionsPage}
      />
      <PublicRoute exact path="/auth/login" component={LoginPage} />
      <PublicRoute
        exact
        path="/auth/forgot-password"
        component={ForgotPasswordPage}
      />
      <PublicRoute
        exact
        path="/auth/change-password/:resetToken"
        component={ChangePasswordPage}
      />
      <PublicRoute
        exact
        path="/auth/register"
        component={RegisterLandingPage}
      />
      <PublicRoute exact path="/cart" component={CartPage} />
      <PublicRoute exact path="/checkout/success" component={PaymentMessages} />
      <PublicRoute exact path="/checkout/cancel" component={PaymentMessages} />
      <PublicRoute
        exact
        path="/userQuiz/:id/pdf-view"
        component={QuizPreviewPage}
      />
      <PublicRoute
        exact
        path="/quiz-preview"
        component={QuizPracticePreviewPage}
      />
      <PublicRoute exact path="/error/404" component={PageNotFound} />
      <Redirect to="/error/404" />

      {!user ? (
        <Redirect exact from="/auth/login" to="/" />
      ) : (
        <Redirect exact from="/" to="/welcome" />
      )}
    </Switch>
  );
};
export default BaseRoutes;
