import React, { useState } from "react";
import { useStyles } from "./styles";
import { useStyles as styles } from "../../uiCore/styles";
import ImgStartQuiz from "../../assets/images/img-start-quiz.jpg";
import { Button } from "../../components";
import { useHistory, useParams } from "react-router";
import { Form } from "react-bootstrap";

const StartQuizPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const globalStyles = styles();
  const { id }: any = useParams();
  const [termsCheck, setTermsCheck] = useState<any>(false);

  return (
    <div className="start-quiz-wrapper">
      <section className={`${globalStyles.section} ${globalStyles.secTextImg}`}>
        <div className="row align-items-center mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              <p>By starting this quiz you agree that you will not share any questions or answers provided to you by CCFP Ready as these are meant for your use only and are the property of CCFP READY</p>
              <strong>Explanation of how the quiz works:</strong>
              <ul className="mt-4 mb-3">
                <li>Typical time to complete the quiz is 2 hours. You will not be able to access the quiz after submission.</li>
                <li>Once you press the start button below you will not be able to pause the quiz and your one time only access has started.</li>
                <li>You will receive an email within 24 hours of submission with feedback and a percentile grade</li>
              </ul>
              <Form.Check
                className="mr-4"
                type={"checkbox"}
                name="termsCheck"
                id={"termsCheck"}
                onChange={() => setTermsCheck(!termsCheck)}
                label={
                  <React.Fragment>
                    I agree to the
                    <a target="_blank" href="/terms-and-conditions">
                      Terms and Conditions
                    </a>
                  </React.Fragment>
                }
              />
              <Button
                variant="primary"
                text="Start"
                className={"text-center"}
                onClick={() => (termsCheck ? history.push(`/quiz/${id}/1`) : null)}
                disabled={termsCheck ? false : true}
              />
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`${classes.imgWrap} right`}>
              <img src={ImgStartQuiz} alt="Header Img" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StartQuizPage;
