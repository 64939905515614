import { Col, Spinner } from "react-bootstrap";
import mapImg from "../../assets/images/mapimg.webp";
import mapImg1 from "../../assets/images/default.png";
import { Link } from "react-router-dom";
import { getAllBlogs } from "./blogsCrud";
import { useEffect, useState } from "react";
import { clamp } from "lodash";
import { useSoosStyles } from "./styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as authActions from "../../redux/reducers/auth/authAction";
import { setBlogId } from "../../utils/const.utils";
export const Blogs = () => {
  const dispatch = useDispatch<any>();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useSoosStyles();

  // Assuming htmlContent is a string containing HTML content

  useEffect(() => {
    async function getBlogData() {
      setLoading(true);
      try {
        let res = await getAllBlogs();
        if (res) {
          // setPage(page);
          setBlogData(res?.data?.results);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
    getBlogData();
    // eslint-disable-next-line
  }, []);
  function getDateUtcIntoLocal(date: any, formate: any) {
    if (date) {
      return moment.utc(date).local().format(formate);
    }
  }
  let desiredWidth = "600px";
  let desiredHeight = "400px";
  return (
    <>
      <div className="col-md-12 mb-4 col-12 d-flex justify-content-center">
        <div
          className="col-md-8 col-11 mt-4"
          style={{
            overflow: "hidden",
            //   width: "100%", // Make sure the image takes the full width of its container
            //   height: "auto", // Maintain the aspect ratio
            //   maxWidth: `${desiredWidth}px`, // Limit the maximum width
            //   maxHeight: `${desiredHeight}px`, // Limit the maximum height
            //   objectFit: "cover",
          }}
        >
          {loading && (
            <div className={classes.spinnercontainer}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {blogData?.map(
            (
              item: {
                id: any;
                title: any;
                picture: any;
                shortDetail: any;
                detail: any;
                createdAt: any;
              },
              index: any
            ) => {
              const titleWords = item.title.split(" ").slice(0, 5).join("-");
              return (
                <Link
                  to={`/blog/${encodeURIComponent(
                    titleWords.replace(/---/g, "-")
                  )}`}
                  style={{ color: "black", textDecoration: "none" }}
                  className={classes.link}
                >
                  <Col
                    lg={12}
                    className={classes.mobile}
                    style={{ border: "1px solid #E2F2FF" }}
                    onClick={() => {
                      setBlogId(item.id);
                    }}
                  >
                    <div className="col-md-6 col-12">
                      <img
                        className="col-12 col-md-12"
                        src={item.picture ? item.picture : mapImg1}
                        alt={`Item ${index + 1}`}
                        height={400}
                        width={"100%"}
                      />
                    </div>
                    <span
                      className="col-md-6 col-12 p-3"
                      style={{ overflow: "auto" }}
                    >
                      <div className="col-7">
                        <div className="" style={{ fontSize: "14px" }}>
                          {getDateUtcIntoLocal(item.createdAt, "MMM DD, YYYY")}
                        </div>
                      </div>
                      <div
                        className={classes.headerTruncate}
                        style={{
                          width: "100%",
                          fontSize: "24px",
                          lineHeight: "36px",
                        }}
                      >
                        {item.title}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          objectFit: "scale-down",
                        }}
                        className={classes.lineTruncate}
                        dangerouslySetInnerHTML={{
                          __html: item.detail,
                        }}
                      />
                    </span>
                  </Col>
                </Link>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};
