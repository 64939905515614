import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as authAction from "./redux/reducers/auth/authAction";
import { Storage } from "react-jhipster";
import { TOKEN_KEY } from "./config/constants";
import { Toaster } from "react-hot-toast";
import { Header, Footer } from "./components";
import BaseRoutes from "./Routes";
import "./uiCore/styles";
import { useStyles } from "./uiCore/styles";
import { createUseStyles } from "react-jss";
import { Colors } from "./uiCore/theme";

const globalStyles = createUseStyles({
  "@global": {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
      margin: 0,
      font: 'normal normal normal 16px/24px "Reem Kufi", sans-serif',
      fontWeight: 300,
      color: "#404040",
      "& #root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      "& .text-primary": {
        color: "#008BBE !important",
      },
    },
  },
});

function App() {
  globalStyles();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch: any = useDispatch();
  const { user } = useSelector((state: any) => ({
    loading: state.authentication.loading,
    user: state.authentication,
  }));

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (
      !location.pathname.includes("change-password") &&
      !location.pathname.includes("checkout") &&
      !location.pathname.includes("pdf-view") &&
      !location.pathname.includes("terms-and-conditions")
    ) {
      if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
        dispatch(authAction.whoAmI()).then(() => {
          history.push("/welcome");
        });
      } else {
        dispatch(authAction.logout());
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
      const decodedJwt = parseJwt(
        Storage.local.get(TOKEN_KEY) ? Storage.local.get(TOKEN_KEY) : Storage.session.get(TOKEN_KEY) ? Storage.session.get(TOKEN_KEY) : ""
      );
      if (user && decodedJwt && decodedJwt.exp * 2000 < Date.now()) {
        dispatch(authAction.logout());
        history.push("/");
      }
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <div className={classes.appWrapper}>
        <Toaster
          position="top-center"
          reverseOrder={false}
          // toastOptions={{
          //   duration: 3000,
          // }}
        />
        {location.pathname !== "/auth/login" &&
        location.pathname !== "/auth/forgot-password" &&
        !location.pathname.includes("change-password") &&
        !location.pathname.includes("pdf-view") ? (
          <Header />
        ) : null}
        <BaseRoutes />
      </div>
      {location.pathname !== "/auth/login" &&
      location.pathname !== "/auth/forgot-password" &&
      !location.pathname.includes("change-password") &&
      !location.pathname.includes("pdf-view") ? (
        <Footer />
      ) : null}
    </>
  );
}

export default App;
