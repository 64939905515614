import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  cartItems: {
    "& .table": {
      borderCollapse: "separate",
      "& td": {
        color: `${Colors.textPrimaryColor}`,
        borderBottomWidth: 2,
        borderColor: "#f4f4f4",
        width: "100%",
        padding: [15, 50],
        fontWeight: 400,
      },
      "& thead": {
        "& th": {
          padding: [15, 50],
          borderBottom: "1px solid #f4f4f4",
          borderBottomWidth: 2,
          fontSize: 18,
          border: 0,
          fontWeight: "normal",
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            fontSize: 22,
          },
          "&.total": {
            "& td": {
              color: Colors.themePrimayColor,
              border: 0,
              fontSize: 24,
            },
          },
        },
      },
    },
  },
  cartPage: {
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
});
