import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  section: {
    margin: [75, 0],
  },
  title: {
    fontSize: 50,
    position: "relative",
    textAlign: "center",
    paddingBottom: 20,
    marginBottom: 50,
    letterSpacing: 2,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: 60,
      height: 3,
      background: Colors.themePrimayColor,
    },
  },
  imgWrap: {
    "& img": {
      // maxWidth: 800,
      width: "100%",
    },
    "&.right": {
      right: 0,
      top: 0,
      "& img": {
        float: "right",
      },
    },
    "&.left": {
      left: 0,
      top: 0,
      "& img": {
        float: "left",
      },
    },
  },
  secEmailSub: {
    background: "#F1F2F4",
    padding: [150, 0],
    marginBottom: 0,
  },
  secQuiz: {
    position: "relative",
    display: "block",
    "& h2": {
      fontSize: 19,
      position: "relative",
      paddingBottom: 15,
      letterSpacing: 2,
    },
    "& li": {
      "& h2": {
        fontSize: "18px !important",
        position: "relative",
        paddingBottom: 15,
        letterSpacing: 2,
        color: "#646464",
      },
    },
    "& .modal": {
      "& .close ": {
        background: "none",
        border: 0,
      },
    },
  },
  secQuizPreview: {
    position: "relative",
    display: "block",
    padding: 0,
    "& h2": {
      fontSize: "20px !important",
      position: "relative",
      paddingBottom: "0 !important",
      letterSpacing: 2,
    },
    "& li": {
      margin: "30px 0",
      "& h2": {
        fontSize: "18px !important",
        position: "relative",
        paddingBottom: "0 !important",
        letterSpacing: 2,
      },
    },
    "& .print-only": {
      display: "none",
    },
    "& .preview-only": {
      display: "block",
    },
    "@media print": {
      padding: "1rem 2rem",
      "& .print-only": {
        display: "block",
      },
      "& .preview-only": {
        display: "none",
      },
      "& h2": {
        fontSize: "18px !important",
        position: "relative",
        paddingBottom: 0,
        letterSpacing: 2,
      },
      "& textarea": {
        fontSize: "16px !important",
      },
    },
    "& hr": {
      backgroundColor: Colors.themePrimayColor,
      height: 3,
      marginBottom: 30,
      "&:last-child": {
        display: "none",
      },
    },
  },
});
