import React, { useEffect } from "react";
import { useStyles } from "./styles";

interface QuestionTextProps {
  questions?: any;
  question?: string;
  answerVal?: any;
  setAnswerVal?: any;
  answers?: any;
  setAnswers?: any;
  correctAnswers?: any;
  id?: string;
  name?: string;
  key?: string;
  value?: any;
  className?: any;
  onChange?: any;
  hasParts?: boolean;
  isPreview?: boolean;
  disabled?: boolean;
}
export const QuizQuestionText = ({
  questions,
  question = "",
  answerVal,
  setAnswerVal,
  answers,
  setAnswers,
  correctAnswers,
  className,
  hasParts = false,
  isPreview = false,
  disabled = false,
}: QuestionTextProps) => {
  const classes = useStyles();

  const updateAnswer = (subIndex: any, item: any, text: any) => {
    if (answers && answers.length) {
      answers.splice(subIndex, 1);
      let payload = { ...item, answer: text };
      answers.splice(subIndex, 0, payload);
    }
    setAnswers([...answers]);
  };
  return (
    <ul className={`${classes.defaultQuestions} ${hasParts && "has-parts"}  ${className && className}`}>
      {questions &&
        questions.length &&
        questions.map((item: any, index: any) => {
          return (
            <li>
              <h2>{item ? item.statement : question}</h2>
              <hr></hr>
              <textarea
                className="form-control"
                placeholder="Enter your answer"
                rows={3}
                id={item?._id}
                name={item?._id}
                value={answers[index]?.answer}
                // {...(isPreview
                //   ? { value: item.answer }
                //   : {
                //       value: item.answer ? answers[index]?.answer : answerVal,
                //     })}
                onChange={(e: any) => {
                  updateAnswer(index, item, e.target.value);
                  item.answer ? setAnswerVal(answers[index]?.answer) : setAnswerVal(item.value);
                }}
                disabled={disabled}></textarea>
              {correctAnswers && <h4 className="text-success mt-3">{item.correct_answers}</h4>}
            </li>
          );
        })}
    </ul>
  );
};
