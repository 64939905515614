import { combineReducers } from "redux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import { authSlice } from "./reducers/auth/authSlice";
import { quizzesSlice } from "./reducers/quizzes/quizzesSlice";
import { checkoutSlice } from "./reducers/checkout/checkoutSlice";
import { soosesSlice } from "./reducers/soos/soosSlice";

export const reducer = combineReducers({
  loadingBar,
  authentication: authSlice.reducer,
  checkout: checkoutSlice.reducer,
  quizzes: quizzesSlice.reducer,
  sooses: soosesSlice.reducer,
});
