import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { QuizItem } from "./QuizItem.component";
// import ArrowDark from "../../assets/images/arrow-dark.svg";
import IconCartLight from "../../assets/images/icon-cart-light.svg";
import { Button } from "../button/button";
import { useStyles } from "./styles";

export const QuizzesList = ({ quizzes, setQuizzes, userQuizzes }: any) => {
  const history = useHistory();
  const classes = useStyles();

  const { cartItems } = useSelector((state: any) => ({
    cartItems: state.quizzes.cartEntities,
  }));

  const userQuizzesPurchased = (item: any, index: any) => {
    if (userQuizzes?.length) {
      let purchasedQuiz = userQuizzes?.filter(
        (it: { id: any; quiz: any; payment_status: any }) =>
          it.quiz === item.id && it.payment_status === "paid"
      );
      if (purchasedQuiz.length) {
        let newData = {
          ...item,
          purchased: true,
          userQuizId: purchasedQuiz[0]?.id,
        };
        quizzes[index] = newData;
        setQuizzes([...quizzes]);
      }
    }
  };
  const userQuizzesAttempted = (item: any, index: any) => {
    if (userQuizzes?.length) {
      let attemptedQuiz = userQuizzes?.filter(
        (it: { id: any; quiz: any; attempted: boolean }) =>
          it.quiz === item.id && it.attempted === true
      );

      if (attemptedQuiz?.length) {
        let newData = {
          ...item,
          attempted: true,
        };
        quizzes[index] = newData;
        setQuizzes([...quizzes]);
      }
    }
  };

  return (
    <>
      {quizzes?.length &&
        quizzes?.map(
          (
            item: {
              id: any;
              addToCart: any;
              purchased: any;
              attempted: any;
              userQuizId: any;
              quizNumber: number;
              name: string | undefined;
              price: string | undefined;
            },
            index: any
          ) => {
            if (item.id !== "62f54f7d4cddc37b341f231c") {
              return (
                <Col lg={12} className="px-lg-4">
                  <QuizItem
                    key={index}
                    className={
                      item?.addToCart || item?.purchased === true
                        ? "selected"
                        : ""
                    }
                    title={`${item?.name} #${item?.quizNumber}`}
                    price={item?.price}
                    purchased={
                      item?.purchased
                        ? item?.purchased
                        : userQuizzesPurchased(item, index)
                    }
                    attempted={
                      item?.attempted
                        ? item?.attempted
                        : userQuizzesAttempted(item, index)
                    }
                    action={
                      item?.purchased && !item?.attempted
                        ? "Start Quiz"
                        : item?.attempted === true
                        ? "Attempted"
                        : !item?.addToCart
                        ? "Add To Cart"
                        : "Remove From Cart"
                    }
                    onClick={(e: any) => {
                      if (item?.purchased && !item?.attempted) {
                        history.push(`/quiz/start/${item?.userQuizId}`);
                      } else if (item?.purchased && item?.attempted === true) {
                        e.PreventDefault();
                      } else {
                        let newQuiz = {
                          ...quizzes[index],
                          addToCart: !quizzes[index].addToCart,
                        };
                        quizzes[index] = newQuiz;
                        setQuizzes([...quizzes]);
                      }
                    }}
                  />
                </Col>
              );
            }
          }
        )}

      {cartItems && cartItems.length >= 1 ? (
        <Button
          className={`ms-3 w-auto ${classes.btnCheckout} ${
            cartItems?.length && "active"
          }`}
          variant={"primary"}
          postfix={<span className="counter">{cartItems.length}</span>}
          prefix={<img src={IconCartLight} alt="arrow" />}
          onClick={() => {
            history.push("/cart");
          }}
          // disabled={
          //   !quizzes.some(
          //     (item: { addToCart: boolean }) => item.addToCart === true
          //   )
          // }
        />
      ) : (
        ""
      )}
    </>
  );
};
