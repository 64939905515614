import React from "react";
import ImgContact from "../../assets/images/img-contact.png";
import { useStyles } from "../../uiCore/styles";
import { Subscription } from "../../components/subscriptions/Subscription";

const ContactPage = () => {
  const classes = useStyles();

  return (
    <div className="contact-wrapper">
      <section className={`${classes.section} ${classes.secContact}`}>
        <div className="row align-items-center mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              <h1>
                <span>Email us at </span>
                <a href="mailto:support@ccfpready.com">support@ccfpready.com</a>
              </h1>
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`img-wrap right`}>
              <img className="img-responsive" src={ImgContact} alt="Header Img" style={{ maxWidth: "100%" }} />
            </div>
          </div>
        </div>
      </section>
      <Subscription />
    </div>
  );
};

export default ContactPage;
