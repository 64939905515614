import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

const PublicRoute = ({ component: Component, isAuthenticated = true, ...rest }) => {
  const { user } = useSelector((state) => state.authentication);
  const renderRedirect = (props) => {
    if (!user) {
      return <Component {...props} />;
    } else {
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={renderRedirect} />;
};

export default PublicRoute;
