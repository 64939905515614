import { createSlice } from "@reduxjs/toolkit";
import { Storage } from "react-jhipster";
import { TOKEN_KEY } from "../../../config/constants";

const initialLoginState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  user: undefined,
  userAuthEntities: undefined,
  role: undefined,
  blogId: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialLoginState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    setBlogId: (state, action) => {
      state.blogId = action.payload.blogId;
    },
    // login
    loginState: (state, action) => {
      state.loading = false;
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    logoutState: (state) => {
      if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
        Storage.local.remove(TOKEN_KEY);
        Storage.session.remove(TOKEN_KEY);
        state.user = undefined;
      }
    },
    // signup
    signupState: (state, action) => {
      state.loading = false;
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    whoAmIState: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.user = action.payload.user;
      state.role = action.payload.user.role;
    },
    saveDeviceState: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
