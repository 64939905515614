import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as checkoutActions from "../../redux/reducers/checkout/checkoutAction";
import { Col, Container, Row, Form } from "react-bootstrap";
import CartItems from "./CartItems";
import RegisterForm from "../auth/register/RegisterForm";
import { Button } from "../../components";
import toast from "react-hot-toast";
import { useStyles } from "./styles";

const CartPage = () => {
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState<any>(false);
  const [termsCheck, setTermsCheck] = useState<any>(false);

  const { cartItems, user } = useSelector((state: any) => ({
    cartItems: state.quizzes.cartEntities,
    user: state.authentication.user,
  }));

  const onCheckout = () => {
    setLoading(true);
    const cartItemIds: Array<string>[] = [];
    if (cartItems?.length && termsCheck === true) {
      cartItems.map((item: any) => cartItemIds.push(item.id));
      dispatch(checkoutActions.addUserQuizCheckout({ quizzes: cartItemIds }))
        .then((response: { data: any }) => {
          window.location.replace(`${response?.data}`);
          setLoading(false);
        })
        .catch((error: any) => {
          error.clientMessage = "Can't find users";
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Please check to agree the Terms and Conditions.");
      setLoading(false);
    }
  };

  return (
    <div className={classes.cartPage}>
      <Container>
        <Row>
          <Col lg={8}>
            <CartItems cartItems={cartItems} />
            {!user ? (
              <RegisterForm cartItems={cartItems} />
            ) : (
              <Row>
                <Col
                  lg={12}
                  className="d-flex justify-content-end align-items-center  my-5 pe-5"
                >
                  <Form.Check
                    className="mr-4"
                    type={"checkbox"}
                    name="termsCheck"
                    id={"termsCheck"}
                    onChange={() => setTermsCheck(!termsCheck)}
                    label={
                      <React.Fragment>
                        I agree to the
                        <a target="_blank" href="/terms-and-conditions">
                          Terms and Conditions
                        </a>
                      </React.Fragment>
                    }
                  />
                  <Button
                    type="submit"
                    align="center"
                    variant="secondary"
                    text="Checkout"
                    loading={loading}
                    disabled={loading ? true : false}
                    onClick={() => {
                      onCheckout();
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartPage;
