import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as authActions from "../../../redux/reducers/auth/authAction";
import { Col, Form, Row } from "react-bootstrap";
import { Button, InputSelect, InputText } from "../../../components";
import { useStyles as styles } from "../../../uiCore/styles";
import { useStyles } from "../styles";
import {
  MIN_LENGTH,
  MAX_LENGTH,
  INVALID_NAME,
  EMAIL_REGEX,
  INVALID_EMAIL,
  INVALID_PASSWORD,
  INVALID_PASSWORD_REGEX,
  PASSWORD_REGEX,
} from "../../../config/validations";
import toast from "react-hot-toast";
import { TOKEN_KEY } from "../../../config/constants";
import { useLocation } from "react-router";
import { Storage } from "react-jhipster";
// import { TOKEN_KEY } from "../../../config/constants";
// import { Storage } from "react-jhipster";

const registerInitialValues = {
  firstName: "",
  lastName: "",
  pgyLevel: "",
  residency: "",
  email: "",
  password: "",
  confirmPassword: "",
  termsCheck: false,
  quizzes: [],
};

const pgyLevelOptions = [
  { value: "pgyLevel1", label: "1" },
  { value: "pgyLevel2", label: "2" },
  { value: "pgyLevel3", label: "3" },
  { value: "pgyLevel4", label: "4" },
  { value: "other", label: "Other" },
];

const RegisterForm = ({ cartItems }: any) => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: registerInitialValues });

  const globalStyles = styles();
  const classes = useStyles();
  const [pgyLevelOption, setPgyLevelOption] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [termsCheck, setTermsCheck] = useState<any>(false);

  const onSubmit = (data: any) => {
    const cartItemIds: Array<any>[] = [];
    if (
      cartItems?.length &&
      data &&
      data.password === data.confirmPassword &&
      termsCheck === true
    ) {
      cartItems.map((item: any, index: any) => {
        return item?.allItemIds
          ? cartItemIds.push(...item.allItemIds)
          : cartItemIds.push(item.id);
      });
      dispatch(authActions.register({ ...data, quizzes: cartItemIds }))
        .then(
          (response: {
            data: { url: any; tokens: { access: { token: any } } };
          }) => {
            window.location.replace(`${response?.data?.url}`);
            Storage.local.set(TOKEN_KEY, response.data.tokens.access.token);
            setLoading(false);
          }
        )
        .catch((error: any) => {
          error.clientMessage = "Can't find users";
          toast.error(error?.response?.data?.message);
        });
      setLoading(true);
    } else if (data.password !== data.confirmPassword) {
      toast.error("Password not matched");
    } else if (termsCheck === false) {
      toast.error("Please check to agree the Terms and Conditions.");
    }
  };

  return (
    <section className={`${globalStyles.section} ${classes.registerForm}`}>
      <h1 className={globalStyles.title}>Pay Now</h1>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <InputText
              className={"mb-4"}
              name="firstName"
              placeholder="First Name"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_NAME} First Name`,
                },
                minLength: { value: 3, message: MIN_LENGTH(3) },
                maxLength: { value: 50, message: MAX_LENGTH(50) },
              }}
              error={
                errors["firstName"]?.message ? errors["firstName"]?.message : ""
              }
            />
            <InputText
              className={"mb-4"}
              name="lastName"
              placeholder="Last Name"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_NAME} Last Name`,
                },
                minLength: { value: 3, message: MIN_LENGTH(3) },
                maxLength: { value: 50, message: MAX_LENGTH(50) },
              }}
              error={
                errors["lastName"]?.message ? errors["lastName"]?.message : ""
              }
            />
            {/* <InputText
              className={"mb-4"}
              name="pgyLevel"
              placeholder="PGY - level"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_NAME} PGY - Level`,
                },
              }}
              error={
                errors["pgyLevel"]?.message ? errors["pgyLevel"]?.message : ""
              }
            /> */}
            <InputSelect
              className={"mb-4"}
              name={"pgyLevel"}
              options={pgyLevelOptions}
              value={pgyLevelOption}
              placeholder="Select Post Graduate Year"
              control={control}
              register={register}
              error={
                errors["pgyLevel"]?.message ? errors["pgyLevel"]?.message : ""
              }
            />
            <InputText
              className={"mb-4"}
              name="residency"
              placeholder="Residency program"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_NAME} Residency program`,
                },
              }}
              error={
                errors["residency"]?.message ? errors["residency"]?.message : ""
              }
            />
          </Col>
          <Col lg={6}>
            <InputText
              className={"mb-4"}
              name="email"
              type="email"
              placeholder={"Email"}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: INVALID_EMAIL,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: INVALID_EMAIL,
                },
              }}
              error={errors["email"]?.message ? errors["email"]?.message : ""}
            />
            <InputText
              className={"mb-4"}
              name="password"
              type={"password"}
              placeholder={"Password"}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_PASSWORD}`,
                },
                pattern: {
                  value: PASSWORD_REGEX,
                  message: INVALID_PASSWORD_REGEX,
                },
              }}
              error={
                errors["password"]?.message ? errors["password"]?.message : ""
              }
            />
            <InputText
              className={"mb-4"}
              name="confirmPassword"
              type={"password"}
              placeholder={"Confirm Password"}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: `${INVALID_PASSWORD} `,
                },
                pattern: {
                  value: `${PASSWORD_REGEX}`,
                  message: INVALID_PASSWORD_REGEX,
                },
              }}
              error={
                errors["confirmPassword"]?.message
                  ? errors["confirmPassword"]?.message
                  : ""
              }
            />
            <Form.Check
              type={"checkbox"}
              name="termsCheck"
              id={"termsCheck"}
              onChange={() => setTermsCheck(!termsCheck)}
              label={
                <React.Fragment>
                  I agree to the
                  <a target="_blank" href="/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </React.Fragment>
              }
            />
          </Col>

          <Col lg={12} className="d-flex justify-content-end">
            <Button
              type="submit"
              align="center"
              variant="primary"
              text="Submit"
              loading={loading}
            />
          </Col>
        </Row>
      </form>
    </section>
  );
};

export default RegisterForm;
