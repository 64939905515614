import React from "react";
import { Container } from "react-bootstrap";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const QuizPageLoader = () => {
  return (
    <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
      <Container>
        <h2>
          <Skeleton height={100} />
        </h2>
        <Skeleton height={50} className="mt-3" />
        <Skeleton height={300} className="mt-3" />
        <Skeleton height={40} width={150} className="mt-3" />
      </Container>
    </SkeletonTheme>
  );
};
