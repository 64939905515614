import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useStyles } from "./styles";
import { QuizQuestionText } from "../../components/index";
import Logo from "../../assets/images/logo-dark.svg";

const QuizPracticePreviewPage = () => {
  const [answerVal, setAnswerVal] = useState<any>("");
  const [answers, setAnswers] = useState<any>([]);
  const printRef = useRef(null);
  const classes = useStyles();

  const { practiceSamp } = useSelector((state: any) => ({
    practiceSamp: state.quizzes.practiceSampEntities,
  }));

  // const printQuiz = useReactToPrint({
  //   content: () => printRef.current,
  // });

  useEffect(() => {
    if (practiceSamp && practiceSamp?.length) {
      setAnswers([...practiceSamp]);
    }
  }, [practiceSamp]);

  return (
    <Container className="mb-lg-5">
      {/* <Button text="Print/Save PDF" onClick={() => printQuiz()} /> */}

      <section ref={printRef} className={`${classes.secQuizPreview}`}>
        <div className="logo w-100 mt-5 print-only">
          <img src={Logo} alt="CCFP - Practive Quizz" width={"200px"} />
        </div>
        <QuizQuestionText
          questions={practiceSamp}
          answers={answers}
          setAnswers={setAnswers}
          answerVal={answerVal}
          setAnswerVal={setAnswerVal}
          correctAnswers
          disabled={true}
        />
      </section>
    </Container>
  );
};
export default QuizPracticePreviewPage;
