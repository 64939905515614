import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quizActions from "../../../redux/reducers/quizzes/quizzesAction";
import * as soosActions from "../../../redux/reducers/soos/soosAction";
import { Container, Row, Col } from "react-bootstrap";
import { useStyles } from "../../../uiCore/styles";
import { Button } from "../../../components";
import ImgPurchaseQuiz from "../../../assets/images/img-purchase-quiz.jpg";
import ArrowDark from "../../../assets/images/arrow-dark.svg";
import IconBulb from "../../../assets/images/icon-bulb-light.svg";
import IconFeedback from "../../../assets/images/icon-feedback-light.svg";
import IconList from "../../../assets/images/icon-list-light.svg";
import { QuizzesList } from "../../../components/quizzes/QuizList.component";
import { QuizItem } from "../../../components/quizzes/QuizItem.component";
import { getCartEntitiesUtil } from "../../../config/constants";
import { SoosList } from "../../../components/soos/SoosList";

const RegisterLandingPage = () => {
  const dispatch = useDispatch<any>();
  const classes = useStyles();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [allCartItems, setAllCartItems] = useState<any>([]);
  const [allCartItemsIds, setAllCartItemsIds] = useState<any>();
  const [quizzesState, setQuizzesState] = useState<any>([]);
  const [soosesState, setSoosesState] = useState<any>([]);
  const { quizzes, cartItems, sooses } = useSelector((state: any) => ({
    quizzes: state.quizzes.entities,
    cartItems: state.quizzes.cartEntities,
    sooses: state.sooses.entities,
  }));

  useEffect(() => {
    dispatch(quizActions.fetchQuizzes());
    dispatch(soosActions.fetchSooses());
  }, [dispatch]);

  useEffect(() => {
    if (quizzes?.length) {
      let allQuizzes = quizzes.map((item: any) => {
        return { ...item, addToCart: false };
      });
      setQuizzesState([...allQuizzes]);
    }
  }, [quizzes]);

  useEffect(() => {
    if (sooses?.length) {
      let allSooses = sooses.map((item: any) => {
        return { ...item, addToCart: false };
      });
      setSoosesState([...allSooses]);
    }
  }, [sooses]);

  useEffect(() => {
    let cartEntities: any = [];
    if (allCartItems && allCartItems?.length) {
      cartEntities = getCartEntitiesUtil(
        quizzesState,
        soosesState,
        true,
        allCartItemsIds,
        totalPrice
      );
    } else {
      cartEntities = getCartEntitiesUtil(quizzesState, soosesState);
    }
    dispatch(quizActions.cartItems(cartEntities));
  }, [dispatch, quizzesState, soosesState]);

  useEffect(() => {
    if (quizzes && quizzes.length) {
      let totalQuizzesPrice = 0;
      quizzes.forEach((item: { price: number }) => {
        if (item.price !== 49) {
          totalQuizzesPrice += item?.price;
        }
      });
      let allPackagePrice = totalQuizzesPrice - totalQuizzesPrice * 0.194;
      setTotalPrice(Math.round(allPackagePrice));
    }
  }, [quizzes, totalPrice]);

  useEffect(() => {
    if (quizzesState?.length) {
      let ids: any = [];
      quizzesState.forEach((item: any) => {
        if (item.price !== 49) {
          ids.push(item.id);
        }
      });

      setAllCartItemsIds(ids);
    }
  }, [quizzesState]);

  const addAllItemsToCart = (key: string) => {
    let cartEntities = getCartEntitiesUtil(
      quizzesState,
      soosesState,
      true,
      allCartItemsIds,
      totalPrice
    );

    if (key === "remove") {
      setAllCartItems([]);
      dispatch(quizActions.cartItems([]));
    } else {
      setAllCartItems(cartEntities);
      dispatch(quizActions.cartItems(cartEntities));
    }
    if (quizzes && quizzes.length > 0) {
      dispatch(quizActions.fetchQuizzes());
    }
  };

  return (
    <div className="landing-wrapper">
      <section className={`${classes.section} ${classes.landerPageText} mt-0`}>
        <div className="row align-items-start mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              <h5>Purchase your SAMPs and SOOs now!</h5>
              <h3 className="mt-1">What do I get when I purchase a quiz?</h3>
              <ul>
                <li>
                  20 SAMP- style questions covering the 105 topics for the CCFP
                  exam
                </li>
                <li>
                  Personalized feedback from qualified family physicians working
                  in Canada
                </li>
                <li>
                  A percentile score to see how you are doing compared to your
                  peers
                </li>
                <li>
                  All results and scores will be emailed to you within 24 hours
                  of submission
                </li>
                <li>
                  We anticipate that you will need 1.5-2 hours to complete each
                  Quiz but you will have no set time limit on these practise
                  quizzes
                </li>
              </ul>
              <h3>What do I get when i purchase a SOO?</h3>
              <ul>
                <li>
                  3 virtual practice SOOs that last 15 minutes each with a
                  simulated patient
                </li>
                <li>
                  Each 15 minute station will cover 2 issues, similarly to the
                  real CCFP exam
                </li>
                <li>
                  You will receive personalized feedback scores and marking
                  sheets within 24 hours of completion of your virtual SOO
                </li>
                <li>
                  The entire session will take between 90-120 minutes to
                  complete
                </li>
                <li>
                  Shortly after purchase you will receive an email with more
                  details on how to log-in to your virtual SOO, exact log-in
                  times, etc
                </li>
              </ul>
              <Button
                onClick={() => {
                  let el: any = document.getElementById("quizzes");
                  el.scrollIntoView();
                }}
                className={"w-auto"}
                variant={"secondary"}
                text={"Click here to proceed"}
                postfix={<img src={ArrowDark} alt="arrow" />}
              />
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`img-wrap right`}>
              <img src={ImgPurchaseQuiz} alt="Header Img" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${classes.section} ${classes.secPromo}`}>
        <Container>
          <div className="row">
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconBulb} alt="CCFP Exam"></img>
                </div>
                <p>
                  Practice answering SAMP-style questions in a similar format to
                  the CCFP exam!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconFeedback} alt="CCFP Exam"></img>
                </div>
                <p>Receive feedback and a grade within 24 hours</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconList} alt="CCFP Exam"></img>
                </div>
                <p>
                  Get prepared with a 3-station virtual SOO in a similar format
                  to the CCFP exam!
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="row">
        <div className="col-lg-6 px-0">
          <section
            id="quizzes"
            className={`${classes.section} ${classes.secPracticeSamp}`}
          >
            <Container>
              <Col>
                <h2 className="p-4">SAMPs:</h2>
                <QuizzesList
                  quizzes={quizzesState}
                  setQuizzes={setQuizzesState}
                />
                {/* {cartItems?.length === 0 && ( */}
                <Col lg={12} className="px-lg-4">
                  <QuizItem
                    className={
                      allCartItems && allCartItems?.length ? "selected" : ""
                    }
                    title={`Package: All ${allCartItemsIds?.length} Quizzes`}
                    price={`${totalPrice}`}
                    action={
                      allCartItems && allCartItems?.length
                        ? "Remove From Cart"
                        : "Add To Cart"
                    }
                    onClick={(e: any) => {
                      if (allCartItems && allCartItems?.length) {
                        addAllItemsToCart("remove");
                      } else {
                        addAllItemsToCart("add");
                      }
                    }}
                  />
                </Col>
                {/* )} */}
              </Col>
            </Container>
          </section>
        </div>
        <div className="col-lg-6 px-0">
          <div className={`${classes.mobileReposive} `}>
            <div className="d-flex">
              PLEASE NOTE THAT ALL TIMES ARE BASED ON EASTERN STANDARD TIME
            </div>
            <div className="d-flex">
              PLEASE NOTE THAT EACH WEEKEND WILL FEATURE UNIQUE SOO CONTENT.
            </div>
          </div>
          <section
            className={`${classes.section} ${classes.secPracticeSamp}`}
            id="userSoos"
          >
            <Container>
              <Col className={`${classes.margin}`}>
                <h2 className="p-4">SOOs:</h2>
                <SoosList
                  sooses={soosesState}
                  setSoosesState={setSoosesState}
                />
                {/* <QuizzesList
                  quizzes={quizzesState}
                  setQuizzes={setQuizzesState}
                /> */}
              </Col>
            </Container>
          </section>
        </div>
      </section>
    </div>
  );
};

export default RegisterLandingPage;
