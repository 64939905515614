import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";

export const STRIPE_PROMISE = loadStripe(
  "pk_test_51IlwZSJFF8T4EY6P2lsj7dAioWnWZGiaHQn4GLX8Y6EHQshGdEHV8zwhJns1d2PBm1eJylHnvYZQL59Z4zwBXSvq00wmQQMM7c"
);
export const SERVER_API_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_ENV = process.env.REACT_APP_MODE;
export const TOKEN_KEY = "ccfp-authentication-token";
export function getDateUtcIntoLocal(date, formate) {
  if (date) {
    return moment.utc(date).local().format(formate);
  }
}
export function getCartEntitiesUtil(
  quizzesState,
  soosesState,
  isSelectAllQuizes = false,
  allCartItemsIds = 0,
  totalPrice = 0
) {
  let cartEntities = [];
  if (quizzesState?.length && !isSelectAllQuizes) {
    cartEntities = quizzesState?.filter((item) => {
      return item.addToCart === true;
    });
  } else {
    cartEntities = [
      {
        allItemIds: allCartItemsIds,
        name: `Package: All ${allCartItemsIds?.length} Quizzes`,
        price: totalPrice,
        addToCart: true,
        totalPrice: 0,
      },
    ];
  }
  if (soosesState?.length) {
    let soosCartEntities = soosesState?.filter((item) => {
      return item.addToCart === true;
    });
    if (soosCartEntities && soosCartEntities.length > 0) {
      for (let index = 0; index < soosCartEntities.length; index += 1) {
        let item = soosCartEntities[index];
        let newItem = {
          id: item?.id,
          quizNumber: getDateUtcIntoLocal(
            item?.startDateTime,
            "dddd, MMMM Do yyyy"
          ),
          name: "SOO ",
          price: item?.price,
        };
        soosCartEntities[index] = newItem;
      }
      cartEntities = cartEntities.concat(soosCartEntities);
    }
  }
  return cartEntities;
}
