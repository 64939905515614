import * as requestFromServer from "./authCrud";
import { authSlice, callTypes } from "./authSlice";
import toast from "react-hot-toast";

// import { TOKEN_KEY } from "../../../config/constants";
// import { Storage } from "react-jhipster";

const { actions } = authSlice;

export const logout = () => (dispatch: any) => {
  dispatch(actions.logoutState());
};

export const login = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer.login(data).then((response) => {
    dispatch(whoAmI());
    dispatch(actions.loginState({ token: response.data.tokens.access.token }));
  });
};

export const register = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer.register(data);
};

export const whoAmI = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .whoAmI()
    .then((response) => {
      dispatch(actions.whoAmIState({ user: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      // toast.error(error.response.data.message);
      dispatch(
        actions.catchError({ error: "error", callType: callTypes.list })
      );
    });
};

export const emailVerify = (code: any, email: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .verifyEmail(code, email)
    .then((response) => {
      dispatch(whoAmI());
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      toast.error(error?.response?.data?.message);
      dispatch(
        actions.catchError({
          error: error.response.data.message,
          callType: callTypes.list,
        })
      );
    });
};

export const sendCodeAgain = (id: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .sendVerificationEmail(id)
    .then((response) => {
      dispatch(whoAmI());
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(
        actions.catchError({
          error: error.response.data.message,
          callType: callTypes.list,
        })
      );
    });
};

export const forgotPassword = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer.forgotPassword(data);
};
export const changePassword = (data: any) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer.changePassword(data);
};
export const setBlogIdForDetail = (id: any) => (dispatch: any) => {
  dispatch(actions.setBlogId(id));
};
