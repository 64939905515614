import React from "react";
import { useHistory } from "react-router";
import { useStyles } from "./style";
import { Card } from "react-bootstrap";
import { Button } from "../../components/index";
import IconDeclined from "../../assets/images/icon-message-declined.svg";

export const PageNotFound = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.cardRedirectMessages}>
      <div className="card-icon">
        <img src={IconDeclined} alt="Icon"></img>
        <h2 className="text-danger">404</h2>
      </div>
      <h3 className="response-text w-100 d-inline-block">Page not found...</h3>

      <Button
        className={"text-center mt-4"}
        text="Return to home"
        variant={"secondary"}
        onClick={() => history.push("/")}
      />
    </Card>
  );
};
