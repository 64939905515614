import React from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX, INVALID_EMAIL } from "../../config/validations";
import { useStyles } from "../../uiCore/styles";
import { Button } from "../button/button";
import { InputText } from "../form/InputText";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import toast from "react-hot-toast";

const subscriptionInitialValues = {
  email: "",
};

interface AboutPageProps {
  className?: string;
}

export const Subscription = ({ className }: AboutPageProps) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: subscriptionInitialValues });

  const onSubmit = (data: any) => {
    toast.success(
      "You have successfully subscribed, we will keep you updated."
    );
  };

  return (
    <section
      className={`${classes.section} ${classes.secEmailSub} ${className}`}
    >
      <Container>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className={`${classes.title} center`}>
              Put your email down to stay updated
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="subscription-wrap">
                <InputText
                  name="email"
                  type="email"
                  placeholder={"Enter your email"}
                  register={register}
                  validations={{
                    required: {
                      value: true,
                      message: INVALID_EMAIL,
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: INVALID_EMAIL,
                    },
                  }}
                  error={
                    errors["email"]?.message ? errors["email"]?.message : ""
                  }
                />
                <Button
                  variant={"secondary"}
                  text={"Sign up Today"}
                  type="submit"
                  postfix={<img src={ArrowDark} alt="arrow" />}
                />
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};
