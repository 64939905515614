import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../uiCore/theme";
import { verify } from "crypto";

export const useSoosStyles = createUseStyles({
  defaultQuestions: {
    transition: Global.transition,
    paddingLeft: "0 !important",
    listStyle: "none",
    counterReset: "list",
    "&.has-parts": {
      "& li": {
        "& h2": {
          "&::before": {
            content: "'Question ('counter(list, lower-alpha)'): '",
            counterIncrement: "list",
          },
        },
      },
    },
    "& li": {
      position: "relative",
      margin: [30, 0],
      fontFamily: Global.fontPrimary,
      display: "block",
      "& h2": {
        fontSize: 26,
        margin: 0,
        letterSpacing: 2,
        padding: 0,
        display: "inline-block",
        "&::before": {
          content: "'Question 'counter(list, number)': '",
          counterIncrement: "list",
        },
        "@media screen and (max-width: 400px)": {
          fontSize: 20,
        },
      },
      "& hr": {
        content: '""',
        width: 50,
        height: 3,
        background: Colors.themePrimayColor,
        opacity: 1,
        marginTop: 20,
        marginBottom: 30,
      },
      "& textarea": {
        borderColor: Colors.themePrimayColor,
        borderRadius: 0,
        padding: [15, 20],
        fontSize: 20,
        "@media screen and (max-width: 680px)": {
          fontSize: 16,
        },
      },
      "&:focus-within": {
        "& h2": {
          color: Colors.themePrimayColor,
        },
        "& textarea": {
          boxShadow: `0 0 5px ${Colors.themePrimayColor}`,
        },
        "&::before": {
          color: Colors.themePrimayColor,
        },
      },
    },
  },
  btnCheckout: {
    borderRadius: "100%",
    width: "70px !important",
    maxWidth: 70,
    maxHeight: 70,
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    right: "1rem",
    bottom: "0",
    transform: "translateY(0)",
    padding: "0 !important",
    margin: "0 !important",
    flexDirection: "column",
    transaction: Global.transition,
    zIndex: 10000,
    "&.active": {
      transform: "translateY(-180px)",
    },
    "& .btn-icon": {
      margin: "0 !important",
      float: "none !important",
    },
    "& img": {
      width: 35,
      maxWidth: 35,
    },
    "& .counter": {
      background: "red",
      borderRadius: "100%",
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: "-.25rem",
      top: "-.25rem",
    },
  },
  priceLayout: {
    color: Colors.themePrimayColor,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    margin: 0,
    marginTop: 10,
    marginBottom: 9,
    lineHeight: 0.5,
    "& span": {
      fontSize: 20,
    },
    "& button": {
      color: Colors.themePrimayColor,
      background: "none",
      border: 0,
      textDecoration: "underline",
      fontSize: 26,
    },
  },
  priceSelectedLayout: {
    color: "white",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    margin: 0,
    marginTop: 10,
    marginBottom: 9,
    lineHeight: 0.5,
    "& span": {
      fontSize: 20,
    },
    "& button": {
      color: "white",
      background: "none",
      border: 0,
      textDecoration: "underline",
      fontSize: 26,
    },
  },
  lineTruncate: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontSize: "18px",
  },
  headerTruncate: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    fontWeight: "800",
  },
  link: {
    transition: "all .4s ease",

    ":hover": {
      backgroundColor: "red",
      opacity: 0.8,
      color: "red",
    },
  },
  spinnercontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  mobile: {
    display: "flex",
    marginTop: "10px",
    "@media screen and (max-width: 768px)": {
      display: "grid",
    },
  },
  mobileDesign: {
    "& img": {
      width: "100%",
      objectFit: "contain",
      height: "800px",
      "@media screen and (max-width: 1200px)": {
        width: "100%",
        objectFit: "contain",
        height: "500px",
      },
      "@media screen and (max-width: 480px)": {
        height: "280px",
      },
    },
  },
});
