import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { SoosItem } from "./SoosItem";
// import ArrowDark from "../../assets/images/arrow-dark.svg";
import IconCartLight from "../../assets/images/icon-cart-light.svg";
import { Button } from "../button/button";
import { useSoosStyles } from "./styles";
import { getDateUtcIntoLocal } from "../../config/constants";
import toast from "react-hot-toast";
import { soosCheckAvailability } from "../../redux/reducers/soos/soosCrud";

export const SoosList = ({ sooses, setSoosesState }: any) => {
  const history = useHistory();
  const classes = useSoosStyles();
  const { cartItems } = useSelector((state: any) => ({
    cartItems: state.quizzes.cartEntities,
  }));
  const [soosesAddToCartProcessing, setSoosesAddToCartProcessing] =
    useState(false);

  const soosAvailabilityCheck = (item: any, index: any) => {
    setSoosesAddToCartProcessing(true);
    soosCheckAvailability(item?.id)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.isAvailable &&
          response.data.isAvailable == true
        ) {
          onClick(item, index);
          setSoosesAddToCartProcessing(false);
        } else {
          showToastForItemSold();
        }
      })
      .catch((error) => {
        setSoosesAddToCartProcessing(false);
        showToastForItemSold();
      });
  };

  const showToastForItemSold = () => {
    toast.error("This date is sold out");
  };

  const onClick = (item: any, index: any) => {
    let newItem = {
      ...sooses[index],
      addToCart: !sooses[index].addToCart,
    };
    sooses[index] = newItem;
    setSoosesState([...sooses]);
  };

  return (
    <>
      {sooses?.length &&
        sooses?.map(
          (
            item: {
              id: any;
              addToCart: any;
              startDateTime: any;
              endDateTime: any;
              timeOfDay: any;
              totalCount: any;
              count: any;
              name: string | undefined;
              price: string | undefined;
            },
            index: any
          ) => {
            return (
              <Col lg={12} className="px-lg-4">
                <SoosItem
                  key={index}
                  className={item?.addToCart ? "selected" : ""}
                  timeOfDay={`${item?.timeOfDay} (between ${getDateUtcIntoLocal(
                    item?.startDateTime,
                    "h:mm a"
                  )}-${getDateUtcIntoLocal(item?.endDateTime, "h:mm a")}) EST`}
                  price={item?.price}
                  title={`${getDateUtcIntoLocal(
                    item?.startDateTime,
                    "dddd, MMMM Do yyyy"
                  )}`}
                  action={
                    item?.count >= item.totalCount
                      ? "Sold Out"
                      : !item?.addToCart
                      ? "Add To Cart"
                      : "Remove From Cart"
                  }
                  onClick={(e: any) => {
                    if (!soosesAddToCartProcessing) {
                      if (item?.count != null && item?.count < 6) {
                        if (item?.addToCart == false) {
                          soosAvailabilityCheck(item, index);
                        } else {
                          onClick(item, index);
                        }
                      } else {
                        showToastForItemSold();
                      }
                    }
                  }}
                />
              </Col>
            );
          }
        )}

      {cartItems && cartItems.length >= 1 ? (
        <Button
          className={`ms-3 w-auto ${classes.btnCheckout} ${
            cartItems?.length && "active"
          }`}
          variant={"primary"}
          postfix={<span className="counter">{cartItems.length}</span>}
          prefix={<img src={IconCartLight} alt="arrow" />}
          onClick={() => {
            history.push("/cart");
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
