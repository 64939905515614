import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useReactToPrint } from "react-to-print";
import * as quizActions from "../../redux/reducers/quizzes/quizzesAction";
import { Container } from "react-bootstrap";
import { useStyles as styles } from "../../uiCore/styles";
import { useStyles } from "./styles";
import { QuizQuestionText } from "../../components/index";
import { useParams } from "react-router";
import Logo from "../../assets/images/logo-dark.svg";

const QuizPreviewPage = () => {
  const { userQuiz } = useSelector((state: any) => ({
    // user: state.authentication.user,
    userQuiz: state.quizzes.userQuizEntities,
  }));

  const classes = useStyles();
  const globalStyles = styles();
  const { id }: any = useParams();
  const dispatch: any = useDispatch();
  const [answers, setAnswers] = useState<any>([]);

  useEffect(() => {
    if (id) {
      dispatch(quizActions.fetchUserQuizPreview(id));
    }
  }, [id, dispatch]);

  return (
    <Container className="mb-lg-5">
      <section className={`quiz-page ${globalStyles.section} ${classes.secQuiz} ${classes.secQuizPreview}`}>
        <div className="logo mb-5">
          <img src={Logo} alt="logo" width="200px" />
        </div>
        {userQuiz && userQuiz.length
          ? userQuiz.map((item: any, index: any) => {
              return (
                <React.Fragment>
                  <h2 className="pb-0">
                    Question {index + 1}: {item?.statement}
                  </h2>
                  <QuizQuestionText
                    hasParts={true}
                    questions={userQuiz[index].parts}
                    answers={userQuiz[index].parts}
                    // answers={userQuiz[index].parts[index]?.answer}
                    setAnswers={setAnswers}
                    correctAnswers
                    disabled={true}
                    isPreview={true}
                  />
                  {/* })
                    : null} */}
                  <hr></hr>
                </React.Fragment>
              );
            })
          : null}
      </section>
    </Container>
  );
};
export default QuizPreviewPage;
