import { useHistory } from "react-router";
import { Button } from "../../components";
import { Container } from "react-bootstrap";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import { useStyles } from "../../uiCore/styles";
import ImgPracticeSamp from "../../assets/images/img-practice-samp.jpg";
import IconBulb from "../../assets/images/icon-bulb-light.svg";
import IconFeedback from "../../assets/images/icon-feedback-light.svg";
import IconList from "../../assets/images/icon-list-light.svg";
import { Subscription } from "../../components/subscriptions/Subscription";

const AboutPage = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="about-wrapper">
      <section className={`${classes.section} ${classes.aboutusPageText}`}>
        <div className="row mx-0">
          <div className="col-lg-6 mx-auto">
            <div className="text-block">
              {/* <h1>
                Simulated quizzes to test to see if you are ready for the
                <span> CCFP Family Medicine Exam!</span>
              </h1> */}
              <h5>Test your knowledge with our simulated SAMPs and SOOs!</h5>
              <h1>SAMPs:</h1>
              <ul>
                {/* <li>Practice SAMPs for the CCFP exam</li>
                <li>Quizzes with similar format to the CCFP exam</li>
                <li>Covers the 105 topics for the CCFP</li>
                <li>Each quiz contains 20 SAMP style questions</li>
                <li>Receive personalised feedback within 24 hours of submission of your quiz</li>
                <li>All SAMP questions and answers are based upon the most recent North American guidelines</li> */}
                <li>Quizzes with similar format to the CCFP exam</li>
                <li>Covers the 105 topics for the CCFP</li>
                <li>Each quiz contains 20 SAMP style questions</li>
                <li>Receive personalised feedback within 24 hours of submission of your quiz</li>
                <li>All SAMP questions and answers are based upon the most recent North American guidelines</li>
              </ul>
              <h1>SOOs: </h1>
              <ul>
              <li>Please note that each weekend will feature unique SOO content</li>
                <li>3 station virtual SOOs that you can take from the comfort of your own home!</li>
                <li>15 minute stations with a simulated patient </li>
                <li>All SOOs will be marked by family physicians who successfully completed the CCFP exam </li>
                <li>Scores and feedback within 24 hours of completion</li>
              </ul>
              <Button
                variant={"secondary"}
                text={"Sign up Today"}
                postfix={<img src={ArrowDark} alt="arrow" />}
                onClick={() => history.push("/auth/register")}
              />
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`img-wrap left`}>
              <img src={ImgPracticeSamp} alt="Header Img" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${classes.section} ${classes.secPromo} mb-0`}>
        <Container>
          <div className="row">
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconBulb} alt="CCFP Exam"></img>
                </div>
                <p>Practice answering SAMP-style questions in a similar to the CCFP exam!</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconFeedback} alt="CCFP Exam"></img>
                </div>
                <p>Receive feedback and a grade within 24 hours</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconList} alt="CCFP Exam"></img>
                </div>
                {/* <p>Covers the 105 topics!</p> */}
                <p>Get prepared with a 3-station virtual SOO in a similar format to the CCFP exam!</p>

              </div>
            </div>
          </div>
        </Container>
      </section>
      <Subscription className="mt-0" />
    </div>
  );
};

export default AboutPage;
