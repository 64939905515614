import axios from "axios";

export const CHECKOUT_URL = "checkout";
export const USER_QUIZ_CHECKOUT_URL = "userQuiz";

export function addUserQuiz(data: any) {
  return axios.post(`${USER_QUIZ_CHECKOUT_URL}`, data);
}

export function sendVerifyCheckoutPayment(data: any) {
  return axios.post(`${CHECKOUT_URL}/verify-payment`, data);
}
