import { isPromise } from "react-jhipster";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";

// const addErrorAlert = (message, key, data) => {
//   key = key ? key : message;
//   if (key === "error.url.not.found") {
//     // toast.error("Unable to reach server");
//   } else {
//     // toast.error(message);
//   }
// };
// eslint-disable-next-line
export default () => (next) => (action) => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }
  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then((response) => {
      return Promise.resolve(response);
    })

    .catch((error) => {
      console.log(error);
      if (error?.message === "Network Error") {
        toast.error(
          "Unable to connect at the moment please check your internet connection or try again latter"
        );
      }
      // if (error?.response) {
      //   if (
      //     !(
      //       error?.response.status === 401 && error.config.url === "auth/whoAmI"
      //     )
      //   ) {
      //     toast.error(error?.response?.data?.message);
      //   }
      // }
      return Promise.reject(error);
    });
};
