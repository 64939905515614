import axios from "axios";

export const AUTH_URL = "auth";

export function login(data: any) {
  return axios.post(`${AUTH_URL}/login`, data);
}

export function register(item: {
  firstName: any;
  lastName: any;
  residency: any;
  pgyLevel: any;
  email: any;
  password: any;
  quizzes: any;
}) {
  let registerData = {
    first_name: item?.firstName,
    last_name: item?.lastName,
    residency: item?.residency,
    pgy_level: item?.pgyLevel?.label,
    email: item?.email,
    password: item?.password,
    quizzes: item?.quizzes,
  };
  return axios.post(`${AUTH_URL}/register`, registerData);
}

export function whoAmI() {
  return axios.get(`${AUTH_URL}/who-am-i`);
}

export function forgotPassword(data: any) {
  return axios.post(`${AUTH_URL}/forgot-password`, data);
}
export function changePassword(data: any) {
  return axios.post(`${AUTH_URL}/reset-password?token=${data.token}`, {
    password: data.password,
  });
}

export function sendVerificationEmail(email: any) {
  return axios.post(`${AUTH_URL}/send-verification-email`, { email: email });
}
export function verifyEmail(code: any, email: any) {
  return axios.patch(`${AUTH_URL}/verify-email`, { code: code, email: email });
}
