import axios from "axios";
export const GET_SOOSES_URL = "blogs";
export function getAllBlogs() {
  return axios.get(
    `${GET_SOOSES_URL}?title=&page=&limit=100&sortBy=&isActive=true`
  );
}
export function getBlogsById(item: any) {
  return axios.get(`${GET_SOOSES_URL}/${item}`, {
    headers: {
      Authorization: `Bearer ${"access-token-" + Math.random()}`,
    },
  });
}
