import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as authActions from "../../redux/reducers/auth/authAction";
import { EMAIL_REGEX, INVALID_EMAIL } from "../../config/validations";
import { Container } from "react-bootstrap";
import { useStyles } from "./styles";
import Logo from "../../assets/images/logo-light.svg";
import ArrowLight from "../../assets/images/arrow-light.svg";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import { Button, InputText } from "../../components";
import toast from "react-hot-toast";

const forgotPasswordInitialValues = {
  email: "",
};

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch<any>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: forgotPasswordInitialValues });

  const onSubmit = (data: any) => {
    dispatch(authActions.forgotPassword(data))
      .then((response: any) => {
        toast.success(`Email has be delivery to you email`);
      })
      .catch((error: any) => {
        error.clientMessage = "Can't find users";
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={classes.authWrapper}>
      <Container className={classes.container}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="text-block">
              <div className={classes.logoWrap}>
                <Link to="/" tabIndex={-1}>
                  <img src={Logo} alt="logo"></img>
                </Link>
              </div>
              <h1>Forgotten your password</h1>
              <strong>
                Already have the password?{" "}
                <Link to="/auth/login" tabIndex={-1}>
                  Login{" "}
                </Link>
                <img src={ArrowLight} alt="arrow" />
              </strong>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <form
              className={classes.formWrap}
              onSubmit={handleSubmit(onSubmit)}
            >
              <h1 className={`${classes.title} left`}>Forgot Password</h1>
              <div className="row">
                <div className="col-lg-12">
                  <InputText
                    name={"email"}
                    type={"email"}
                    placeholder={"Email"}
                    autoComplete="off"
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: INVALID_EMAIL,
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: INVALID_EMAIL,
                      },
                    }}
                    error={
                      errors["email"]?.message ? errors["email"]?.message : ""
                    }
                  />
                  <Link to="/auth/login" tabIndex={-1}>
                    Login?
                  </Link>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 mt-5">
                  <Button
                    type={"submit"}
                    className={"float-end"}
                    text="Submit"
                    postfix={<img src={ArrowDark} alt="arrow" />}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ForgotPasswordPage;
