import React from "react";
import { useStyles } from "../../uiCore/styles";
import { useHistory } from "react-router";
import { Container } from "react-bootstrap";
import { Button } from "../../components";
import PracticeStatementsPage from "../../components/quizzes/QuizPractice.component";
import ImgGetReady from "../../assets/images/img-get-ready.png";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import IconBulb from "../../assets/images/icon-bulb-light.svg";
import IconFeedback from "../../assets/images/icon-feedback-light.svg";
import IconList from "../../assets/images/icon-list-light.svg";
import { useSelector } from "react-redux";
// import ImgPracticeSamp from "../../assets/images/img-practice-samp.jpg";

const LandingPage = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="landing-wrapper">
      <section className={`${classes.section} ${classes.mainlanderPageText}`}>
        <div className="row align-items-start mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              {/* <h1>
                Get ready for the <span>CCFP Family Medicine Exam!</span>
              </h1> */}
              <h5>Get ready for the CCFP Exam!</h5>
              <h5>Practice SAMPs and SOOs for the CCFP exam</h5>
              <h1>SAMPs:</h1>
              <ul>
              <li>Each quiz contains 20 SAMP style questions</li>
              <li>Receive personalised feedback within 24 hours of submission of your quiz</li>
              <li>All SAMP questions and answers are based upon the most recent North American guidelines</li>
              </ul>
              <h1>SOOs (virtual):</h1>
              <ul>
              <li>Please note that each weekend will feature unique SOO content</li>
              <li>15 minute practice virtual SOOs with simulated patients</li>
              <li>Each practice SOO consists of 3 different stations</li>
              <li>2 topics will be covered in each 15 minute SOO</li>
                <li>All SOOs will be marked by family physicians and you will receive a mark and feedback within 24 hours</li>
                </ul>
              {/* <ul>
                <li>Practice SAMPs for the CCFP exam</li>
                <li>Quizzes with similar format to the CCFP exam</li>
                <li>Covers the 105 topics for the CCFP</li>
                <li>Each quiz contains 20 SAMP style questions</li>
                <li>
                  Receive personalised feedback within 24 hours of submission of
                  your quiz
                </li>
                <li>
                  All SAMP questions and answers are based upon the most recent
                  North American guidelines
                </li>
              </ul> */}
              <Button
                variant={"secondary"}
                text={"Sign up Today"}
                postfix={<img src={ArrowDark} alt="arrow" />}
                onClick={() => history.push("/auth/register")}
              />
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`img-wrap right`}>
              <img src={ImgGetReady} alt="Header Img" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${classes.section} ${classes.secPromo}`}>
        <Container>
          <div className="row">
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconBulb} alt="CCFP Exam"></img>
                </div>
                <p>
                  Practice answering SAMP-style questions in a similar format to
                  the CCFP exam!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconFeedback} alt="CCFP Exam"></img>
                </div>
                <p>Receive feedback and a grade within 24 hours</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-block">
                <div className="icon-wrap">
                  <img src={IconList} alt="CCFP Exam"></img>
                </div>
                {/* <p>Covers the 105 topics!</p> */}
                <p>Get prepared with a 3-station virtual SOO in a similar format to the CCFP exam!</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className={`${classes.section} ${classes.secTextImg} ${classes.secPracticeSamp}`}
      >
        <div className="container">
          <div className="row mx-0">
            {/* <div className="col-lg-6 px-0">
            <div className={`img-wrap left`}>
              <img src={ImgPracticeSamp} alt="Header Img" />
            </div>
          </div> */}
            <div className="col-lg-12 mx-auto">
              <div className="text-block text-center">
                <h1 className={`${classes.title} center`}>Practice SAMP</h1>
                <h4 className="pe-lg-5 me-lg-5">
                  <b>
                    Here is an example of what a SAMP on the quiz will look
                    like:
                  </b>
                </h4>
                <h4 className="pe-lg-5 me-lg-5">
                  Answers would be emailed within 24 hours after completion of
                  quiz
                </h4>
                <p>
                  A 72-year-old woman comes into your outpatient family medicine
                  clinic with complaints of fatigue for the last few months. She
                  tells you that she feels tired despite sleeping 7 hours every
                  night, she also has recently noticed shortness of breath at
                  night requiring her to use an extra pillow. She has a past
                  medical history of atrial fibrillation, hypertension and
                  recurrent UTIs. She is a non-smoker and drinks 3 units of
                  alcohol per week. She currently takes Metoprolol, rivaroxaban,
                  ramipril, and trimethoprim daily.
                  <br />
                  <br />
                  <b className="d-block w-100 mb-3">Your exam is as follows</b>
                  Vitals: T 36.3 HR 100 RR 18 O2 sat: 93% on room air BP: 112/81
                  <br />
                  Crepitus to both lung bases, +3 pitting edema to both knees{" "}
                  <br /> No obvious murmurs on auscultation, pulse irregularly
                  irregular <br /> <br />
                  You order the appropriate investigations for this patient and
                  diagnose her with heart failure with a reduced ejection
                  fraction.
                </p>
              </div>
            </div>
          </div>
        </div>
        <PracticeStatementsPage />
      </section>
    </div>
  );
};

export default LandingPage;
