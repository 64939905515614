import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  cardQuiz: {
    position: "relative",
    border: 0,
    padding: [50, 30],
    boxShadow: "3px 5px 20px rgba(0,0,0,.1)",
    borderRadius: 0,
    marginBottom: 50,
    "&.selected": {
      background: Colors.themePrimayColor,
      color: "white",
      "& h1": {
        color: "white",
        "& button": {
          color: "white",
        },
      },
    },
    "& h2": {
      fontSize: 36,
      fontWeight: 400,
      textTransform: "capitalize",
    },
    "& h1": {
      color: Colors.themePrimayColor,
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      margin: 0,
      marginTop: 40,
      lineHeight: 0.5,
      "& span": {
        "@media screen and (max-width: 768px)": {
          fontSize: 30,
        },

        fontSize: 62,
      },
    },
    "& .badge-purchased": {
      textAlign: "center",
      position: "absolute",
      top: 30,
      right: 60,
    },
    "& .card-action": {
      color: Colors.themePrimayColor,
      background: "none",
      border: 0,
      textDecoration: "underline",
      fontSize: 26,
    },
  },
});
