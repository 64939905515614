import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../uiCore/theme";

let inputHeight = 60;
let inputPadding = [0, 16];

export const useStyles = createUseStyles({
  inputDefautStyle: {
    "& .input-wrap": {
      padding: inputPadding,
      height: inputHeight,
      fontSize: 20,
      minWidth: 200,
      fontWeight: 600,
      border: [2, "solid", Colors.themePrimayColor],
      boxShadow: Global.btnShadow,
      transition: Global.transition,
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      "& .form-control": {
        background: "none",
        fontSize: 20,
        border: 0,
        padding: 0,
        boxShadow: "none",
      },
      "&:placholder": {
        color: "rgba(0,0,0,.5)",
      },
      "&:focus-within": {
        boxShadow: `0 0 5px ${Colors.themePrimayColor}`,
      },
    },
    "& .prefix": {
      marginRight: 10,
      "& img": {
        width: 24,
        verticalAlign: -4,
      },
    },
    "& .postfix": {
      float: "right",
    },
    "& .input-validation-error": {
      color: "red",
    },
  },
  defaultInputSelect: {
    width: "100%",
    border: "none !important",
    outline: "none !important",
    boxShadow: "none !important",
    background: "none !important",
    "& .input-wrap": { padding: 0 },
    "& .input-select__control": {
      borderRadius: 0,
      border: 0,
      height: "auto",
      background: "none",
      boxShadow: "none",
      "&:focus-within": {
        border: 0,
        outline: 0,
        boxShadow: "none",
      },
    },
    "& .input-select__placeholder": {
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // position: "absolute",
      // left: "0.5rem",
      // top: "0.65rem",
    },
    "& .input-select__value-container": {
      display: "flex",
      flexWrap: "nowrap",
      height: inputHeight,
      padding: inputPadding,
      // .input-select__single-value {}
      "& .input-select__input-container": {
        height: inputHeight,
        minWidth: "auto !important",
        width: "auto !important",
      },
    },
    "& .input-select__indicators": {
      paddingRight: 0,
    },
    "& .input-select__indicatorSeparator": {
      display: "none",
    },
    "& .input-select__menu": {
      position: "absolute",
      top: `${inputHeight} !important`,
      left: 0,
      borderRadius: 0,
      maxHeight: "auto",
      minHeight: "auto",
      padding: ".5rem",
      "& .input-select__menu-list": {
        "& .input-select__option": {
          background: "#f6f6f6",
          marginBottom: "0.5rem",
          border: 0,
          color: Colors.textPrimaryColor,
          textAlign: "left !important",
          padding: "0.5rem 1rem",
          width: "100%",
          "&.input-select__option--is-focused": {
            background: Colors.themePrimayColor,
            color: "white",
          },
          "&.input-select__option--is-selected": {
            background: Colors.themePrimayColor,
            color: "white",
            // "&:after": {
            //   content: "",
            //   background:
            //     "url(../images/icon-check.svg) right center no-repeat",
            //   backgroundSize: "100%",
            //   float: "right",
            //   width: 20,
            //   height: 20,
            // },
          },
        },
      },
    },
  },
});
