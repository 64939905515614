import axios from "axios";

export const GET_QUIZZES_URL = "quizzes";
export const GET_USER_QUIZZES_URL = "userQuiz";

// GET all quizs
export function getAllQuizzes() {
  return axios.get(`${GET_QUIZZES_URL}?sortBy=createdAt:asc`);
}
// GET single quiz by id
export function getQuizById(id: any) {
  return axios.get(`${GET_QUIZZES_URL}/${id}`);
}

// GET all user quizs
export function getAllUserQuizzes() {
  return axios.get(`${GET_USER_QUIZZES_URL}`);
}
// GET single quiz by id
export function getUserQuizById(id: any) {
  return axios.get(`${GET_USER_QUIZZES_URL}/${id}`);
}

export function getUserQuizPreviewById(id: any) {
  return axios.get(`${GET_USER_QUIZZES_URL}/${id}/pdf-view`);
}

// POST user answers
export function postUserQuestion(
  id: any,
  payload: { questionId: any; parts: any[] }
) {
  return axios.post(`${GET_USER_QUIZZES_URL}/${id}/question`, payload);
}

// POST user quizs
export function postUserQuiz(id: any) {
  return axios.post(`${GET_USER_QUIZZES_URL}/${id}`);
}
