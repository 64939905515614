import * as requestFromServer from "./soosCrud";
import { soosesSlice, callTypes } from "./soosSlice";

const { actions } = soosesSlice;

export const fetchSooses = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getAllSooses()
    .then((response) => {
      const data = response.data.results;
      dispatch(
        actions.soosesFetched({
          entities: data,
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find getAllSooses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
