import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import mapImg from "../../assets/images/default.png";
import mapImg1 from "../../assets/images/mapimg.webp";
import studyTips from "../../assets/images/studyTips.png";
// import ArrowDark from "../../assets/images/arrow-dark.svg";
// import  from "./styles";
import toast from "react-hot-toast";
import { getBlogsById } from "./blogsCrud";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { blogId } from "../../utils/const.utils";
import { useSoosStyles } from "./styles";

const DetailBlogs = () => {
  const [blogDetail, setBlogDetail] = useState<any>(null);
  const [blogShortDetail, setBlogShortDetail] = useState<any>(null);
  const [blogTitle, setBlogTitle] = useState<any>(null);
  const [blogPicture, setBlogPicture] = useState<any>(null);
  const { id }: any = useParams();
  const classes = useSoosStyles();
  const myArray = [
    {
      header: "Wounds",
      headerTitle:
        "Assess for superficial and deep infections. ‘Mediastinal clicking' is worrying for non-union.",
    },
    {
      header: "Wounds",
      headerTitle:
        "Assess for superficial and deep infections. ‘Mediastinal clicking' is worrying for non-union.",
    },
  ];
  useEffect(() => {
    async function getSoosesData(id: any) {
      try {
        let res = await getBlogsById(id);
        if (res) {
          // setPage(page);

          setBlogDetail(res?.data?.detail);
          setBlogShortDetail(res?.data?.shortDetail);
          setBlogTitle(res?.data?.title);
          setBlogPicture(res?.data?.picture);
        }
      } catch (e) {}
    }
    getSoosesData(blogId);
    // eslint-disable-next-line
  }, []);
  const htmlContent = blogDetail;
  const desiredWidth = 1000;
  const desiredHeight = 800;
  return (
    <>
      <div className="col-md-12 col-12 d-flex justify-content-center"></div>
      <div className="col-12 d-flex justify-content-center">
        <div className="col-md-9 col-11">
          <div className="col-12">
            <div style={{ fontSize: "18px" }} className="mb-5">
              All Posts
            </div>
          </div>
          <div style={{ border: "1px solid #E2F2FF" }} className="mb-5">
            <Col lg={12} className="d-flex justify-content-center mb-5">
              <div className="col-10">
                <div className="col-12">
                  <div className="mt-5 mb-3" style={{ fontSize: "18px" }}>
                    {/* The Review Course in Family Medicine 23 hours ago 1 min */}
                  </div>
                  <div
                    className="mb-5"
                    style={{ fontSize: "55px", lineHeight: "70px" }}
                  >
                    {blogTitle}
                  </div>
                </div>
                <div className="col-12">
                  <img
                    className="col-12"
                    src={blogPicture ? blogPicture : mapImg}
                    alt={``}
                    style={{
                      width: "100%", // Make sure the image takes the full width of its container
                      height: "auto", // Maintain the aspect ratio
                      //  maxWidth: `${desiredWidth}px`, // Limit the maximum width
                      //     maxHeight: `${desiredHeight}px`, // Limit the maximum height
                      objectFit: "contain",
                    }}
                  />
                  {/* <div
                    className="mt-5"
                    style={{ fontSize: "18px", lineHeight: "35px" }}
                  >
                    {blogShortDetail}
                  </div> */}
                </div>
              </div>
            </Col>

            <Col lg={12} className="d-flex justify-content-center mb-5">
              <div className="col-10" style={{ overflow: "hidden" }}>
                <div className="col-12 cover-image">
                  <div
                    className={classes.mobileDesign}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  />
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailBlogs;
