import { Container } from "react-bootstrap";
import { useStyles } from "../../uiCore/styles";

const TermsAndConditionsPage = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.tpfWrapper} py-5`}>
      <Container>
        <div className="row">
          <div className="page-wrapper col-lg-8 mx-auto">
            <div className="d-flex justify-content-center pb-2">
              <h5>CCFP READY</h5>
            </div>
            <div className="d-flex justify-content-center pb-2">
              <h5>Terms and Conditions</h5>
            </div>
            <div className="mb-4 ml-3">
              In agreeing to these terms of conditions I understand and agree to
              the following:{" "}
            </div>
            <ul>
              <li>
                <p>
                  You understand that all CCFP READY content is only available
                  in English
                </p>
              </li>
              <li>
                <p>
                  You understand that CCFP READY is not affiliated with the CFPC
                  or part of the creation of the CCFP examination in any way
                </p>
              </li>
              <li>
                <p>
                  You acknowledge that CCFP READY endeavors to maintain the
                  accuracy and currency of the questions and answers based on
                  the latest North American guidelines. These materials are
                  carefully created and reviewed by a successful CCFP graduate.
                  However, you understand that CCFP READY cannot be held liable
                  for any potential misinformation contained within these
                  questions, as they are susceptible to human error.
                </p>
              </li>
              <li>
                <p>
                  You acknowledge and agree that you will not distribute,
                  reproduce, or share any CCFP READY content, including quizzes
                  (SAMPs) and SOOs, without explicit permission from us.
                  Furthermore, you understand that the purpose of the
                  restriction on screen recording, screenshotting, or taking
                  pictures or videos of CCFP READY content is to protect the
                  integrity and confidentiality of the educational material
                  provided. By adhering to these guidelines, you ensure the
                  security and privacy of the content, as well as the
                  educational value it holds for other users.
                </p>
              </li>
              <li>
                <p>
                  In order to respect the privacy and confidentiality of the
                  actors involved in the virtual SOO (Structured Oral
                  Examination) practice exam, refrain from screen-recording,
                  taking videos, or capturing pictures during the session. By
                  refraining from recording or capturing any media during the
                  virtual SOO practice exam, you demonstrate your commitment to
                  upholding their privacy rights.
                </p>
              </li>
              <li>
                <p>
                  CCFP READY cannot be held liable for any technical
                  difficulties that may arise on your end during the virtual
                  practice SOOs or quizzes (SAMPs). It is your responsibility to
                  ensure that you have a stable internet connection, compatible
                  devices, and appropriate software or platforms to access and
                  participate in the CCFP READY program. We recommend checking
                  your system requirements and conducting any necessary tests or
                  updates prior to engaging in the virtual practice sessions.
                </p>
              </li>
              <li>
                <p>
                  You agree to ensure that during the Virual Practice SOOs or
                  Quizzes, you are in a quiet environment, free from
                  distractions that may interfere with your concentration or the
                  quality of the session. To optimize your experience and
                  minimize any potential disruptions, it is expected that you
                  use headphones or earphones during the practice. This helps to
                  enhance audio clarity, reduce background noise, and maintain
                  the privacy of the session.
                </p>
              </li>
              <li>
                <p>
                  You acknowledge and agree that you will not share any
                  questions or answers provided to you by CCFP READY with anyone
                  else. These materials are intended for your personal use only
                  and are the exclusive property of CCFP READY. Any unauthorized
                  distribution,reproduction, or sharing of the questions or
                  answers may result in legal action and the termination of your
                  access to the CCFP READY platform.
                </p>
              </li>
              <li>
                <p>
                  You agree that CCFP READY cannot be held liable for your
                  actual exam score. While CCFP READY aims to provide
                  comprehensive and effective preparation materials, the outcome
                  of the exam ultimately depends on various factors, including
                  your individual performance and established evaluation
                  criteria and standards set by certification authorities. By
                  participating in the CCFP READY program, you acknowledge and
                  understand that CCFP READY is not responsible for, nor can it
                  guarantee, your CCFP exam score.
                </p>
              </li>
              <li>
                <p>All sales are final.</p>
              </li>
              <li>
                <p>
                  Any dispute or resolution of any dispute with regards to above
                  terms and conditions will be subject to the laws of Ontario.
                </p>
              </li>
            </ul>
            <div className="mb-4 ml-3 pt-4">
              We appreciate your understanding and cooperation in maintaining a
              respectful and professional environment for all participants
              involved in the virtual SOO practice exam. By agreeing to these
              terms, you acknowledge the importance of confidentiality and
              commit to using the provided materials solely for your educational
              purposes. This ensures fairness, accountability, and the continued
              success of the CCFP READY program for all participants.
            </div>
            <div className="d-flex justify-content-end pt-5 pr-2">
              <div>I Agree and acknowledge above terms and conditions:</div>
            </div>
            {/* <h4 className="mt-4">
              You agree that you will not share any questions or answers
              provided to you by CCFP ready as these are meant for your use only
              and are the property of{" "}
              <span className="text-primary">CCFP READY</span>
            </h4> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TermsAndConditionsPage;
