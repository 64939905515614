import toast from "react-hot-toast";
import * as requestFromServer from "./quizzesCrud";
import { quizzesSlice, callTypes } from "./quizzesSlice";

const { actions } = quizzesSlice;

export const fetchQuizzes = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getAllQuizzes()
    .then((response) => {
      const data = response.data.results;
      dispatch(
        actions.quizzesFetched({
          entities: data,
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quizzes";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchQuiz = (id: any) => (dispatch: (arg0: any) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getQuizById(id)
    .then((response: { data: any }) => {
      const quiz = response?.data?.questions;
      dispatch(
        actions.quizFetched({
          singleQuiz: quiz,
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quiz";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUserQuizzes = () => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUserQuizzes()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.userQuizzesFetched({
          userQuizzesEntities: data.results,
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quizzes";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserQuiz = (id: any) => (dispatch: (arg0: any) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserQuizById(id)
    .then((response: { data: any }) => {
      const quiz = response?.data?.questions;
      dispatch(
        actions.userQuizFetched({
          userQuizEntities: quiz,
        })
      );
    })
    .catch((error: { clientMessage: string }) => {
      error.clientMessage = "Can't find quiz";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchUserQuizPreview =
  (id: any) => (dispatch: (arg0: any) => void) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .getUserQuizPreviewById(id)
      .then((response: { data: any }) => {
        const quiz = response?.data;
        dispatch(
          actions.userQuizFetched({
            userQuizEntities: quiz,
          })
        );
      })
      .catch((error: { clientMessage: string }) => {
        error.clientMessage = "Can't find quiz";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const submitUserQuestion =
  (id: any, payload: { questionId: any; parts: any[] }) =>
    (dispatch: (arg0: { payload: any; type: string }) => void) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      return requestFromServer.postUserQuestion(id, payload);
    };

export const submitUserQuiz =
  (id: any) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.postUserQuiz(id);
  };

export const cartItems = (data: any) => (dispatch: (arg0: any) => void) => {
  dispatch(
    actions.cartItemEntities({
      cartItemEntities: data,
    })
  );
};

export const practiceSamp = (data: any) => (dispatch: (arg0: any) => void) => {
  dispatch(
    actions.practiceSampEntities({
      practiceSampEntities: data,
    })
  );
};
