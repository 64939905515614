import { createUseStyles } from "react-jss";
import { Colors, Global } from "./theme";

export const useStyles = createUseStyles({
  appWrapper: {
    flex: "1 0 auto",
    font: 'normal normal normal 16px/24px "Reem Kufi", sans-serif',
    "& .container": {
      maxWidth: "1680px !important",
    },
  },
  section: {
    margin: [75, 0],
    "@media screen and (max-width: 991px)": {
      margin: [30, 0],
    },
  },
  title: {
    fontSize: 42,
    position: "relative",
    letterSpacing: 2,
    paddingBottom: 20,
    "&.left": {
      margin: "0 0 50px",
      textAlign: "left",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 50,
        height: 3,
        background: Colors.themePrimayColor,
      },
    },
    "&.center": {
      margin: "0 auto 50px",
      textAlign: "center",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: 60,
        height: 3,
        background: Colors.themePrimayColor,
      },
    },
  },
  secTextImg: {
    position: "relative",
    marginTop: 0,
    "& .text-block": {
      padding: [50, 20, 50, 115],
      "@media screen and (max-width: 1680px)": {
        padding: "30px 0px 50px !important",
      },
    },
    "& .scroll-to": {
      fontSize: 30,
      color: Colors.themePrimayColor,
    },
    "& h1": {
      fontWeight: 700,
      fontSize: 40,
      maxWidth: 700,
      marginBotton: 30,
      "@media screen and (max-width: 680px)": {
        fontSize: 30,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& p": {
      fontSize: 24,
      marginTop: 20,
    },
    "& strong": {
      display: "block",
      fontSize: 24,
      fontWeight: 500,
      marginTop: 30,
    },
    "& ul": {
      paddingLeft: 20,
      margin: [75, 0],
      fontFamily: Global.fontSecondary,
      "@media screen and (max-width: 1200px)": {
        marginTop: "30px !important",
        marginBottom: "30px !important",
      },
      "& li": {
        fontSize: 24,
        marginBottom: 26,
        fontWeight: 500,
        lineHeight: 1.25,
        "@media screen and (max-width: 1200px)": {
          fontSize: 20,
        },
        "&::marker ": {
          fontSize: 20,
          color: Colors.textPrimaryColor,
        },
      },
    },
    "& .img-wrap": {
      "& img": {
        maxWidth: 900,
        width: "100%",
      },
      "&.right": {
        right: 0,
        top: 0,
        "& img": {
          float: "right",
        },
      },
      "&.left": {
        left: 0,
        top: 0,
        "& img": {
          float: "left",
        },
      },
    },
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
  secPromo: {
    background: "#01878c",
    padding: [60, 0],
    margin: [75, 0, 150],
    fontFamily: Global.fontSecondary,
    "@media screen and (max-width: 991px)": {
      margin: [75, 16],
    },
    "& .info-block": {
      textAlign: "center",
      color: "#ffffff",
      maxWidth: 400,
      margin: "0 auto",
      "@media screen and (max-width: 991px)": {
        padding: [30, 0],
      },
      "& img": {
        height: 75,
      },
      "& p ": {
        marginTop: 20,
        marginBottom: 0,
        fontSize: 24,
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
  },
  secPracticeSamp: {
    position: "relative",
    "& .text-block": {
      padding: [40, 50, 40, 0],
      "& h4": {
        fontFamily: Global.fontSecondary,
        fontSize: 26,
      },
      "& p": {
        fontFamily: Global.fontSecondary,
        fontWeight: 500,
        lineHeight: 1.25,
        fontSize: 22,
        background: "#F1F2F4",
        padding: [30],
        margin: [30, 0],
      },
    },
  },
  secContact: {
    position: "relative",
    marginTop: 0,
    "& .text-block": {
      paddingLeft: 155,
      "& h1": {
        fontSize: 40,
      },
      "@media screen and (max-width: 1600px)": {
        paddingLeft: 16,
        "& h1": {
          fontSize: 36,
        },
      },
      "@media screen and (max-width: 991px)": {
        marginTop: 30,
        marginBottom: 30,
      },
      "@media screen and (max-width: 600px)": {
        "& h1": {
          fontSize: 30,
        },
      },
    },
    "& h1": {
      fontSize: 52,
      maxWidth: 700,
      marginBotton: 30,
      wordBreak: "break-all",
      fontFamily: Global.fontSecondary,
      "@media screen and (max-width: 680px)": {
        fontSize: 30,
      },
      "& span": {
        fontWeight: 600,
      },
      "& a": {
        fontWeight: 700,
        display: "block",
        color: Colors.themePrimayColor,
        textDecoration: "none",
      },
    },
  },
  secEmailSub: {
    background: "#F1F2F4",
    padding: [150, 0],
    marginBottom: 0,
    "& .subscription-wrap": {
      display: "flex",
      alignItems: "center",
      "& button": {
        marginLeft: 20,
      },
      "@media screen and (max-width: 680px)": {
        flexDirection: "column",
        "& button": {
          marginTop: 20,
        },
      },
      "@media screen and (max-width: 480px)": {
        "& button": {
          width: "100%",
          marginLeft: 0,
        },
      },
    },
  },
  tpfWrapper: {
    "& .page-wrapper": {
      background: "#ffffff",
      borderRadius: "5px",
      margin: "0 auto",
    },
    "& .page-title": {
      marginBottom: "2rem",
      " & h1": {
        marginBottom: 0,
        fontSize: "2rem !important",
      },
    },
    "& h5": {
      fontWeight: 500,
      marginBottom: 0,
      fontSize: "1.15rem",
    },
    "& h4": {
      marginBottom: "1.5rem",
      fontSize: "1.15rem",
      lineHeight: 1.5,
    },
    "& p": {
      fontSize: "0.9rem",
    },
    "& ul": {
      margin: 0,
      // padding: 0,
      // listStyle: "none",
      "& li": {
        marginBottom: "1.5rem",
        paddingRight: "5vh",
        "&:last-child": {
          marginBottom: 0,
        },
      },
      "&.privacy-items": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        "& li": {
          flex: "0 0 50%",
          display: "flex",
          "@media screen and (max-width: 770px)": {
            flex: "0 0 100%",
            "&:last-child": {
              marginBottom: 0,
            },
          },
          "& .icon-wrap": {
            marginRight: "1rem",
          },
          "& h3": {
            fontSize: "1.1rem",
          },
          "& h4": {
            fontSize: "1rem",
            marginBottom: 0,
          },
        },
      },
    },
    "& .privacy-footer": {
      fontSize: "0.9rem",
      "& a": {
        fontWeight: 500,
        color: "#3f3f3f",
        marginRight: "0.25rem",
      },
    },
  },
  landerPageText: {
    position: "relative",
    marginTop: 0,
    "& .text-block": {
      padding: [50, 20, 50, 115],
      "@media screen and (max-width: 1680px)": {
        padding: "0px 0px 50px !important",
      },
    },
    "& .scroll-to": {
      fontSize: 30,
      color: Colors.themePrimayColor,
    },
    "& h1": {
      fontWeight: 700,
      fontSize: 40,
      maxWidth: 700,
      marginBotton: 30,
      "@media screen and (max-width: 680px)": {
        fontSize: 30,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& .landingPageHeading": {
      fontWeight: 700,
      fontSize: 16,
      maxWidth: 700,
      marginBotton: 30,
      display: "flex",
      justifyContent: "center",
      "@media screen and (max-width: 680px)": {
        fontSize: 12,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& p": {
      fontSize: 24,
      marginTop: 20,
    },
    "& strong": {
      display: "block",
      fontSize: 24,
      fontWeight: 500,
      marginTop: 30,
    },
    "& ul": {
      paddingLeft: 20,
      margin: [30, 0],
      lineHeight: 1,
      fontFamily: Global.fontSecondary,
      "@media screen and (max-width: 1200px)": {
        marginTop: "30px !important",
        marginBottom: "30px !important",
      },
      "& li": {
        fontSize: 15,
        marginBottom: 10,
        fontWeight: 400,
        lineHeight: 1.25,
        "@media screen and (max-width: 1200px)": {
          fontSize: 13,
        },
        "&::marker ": {
          fontSize: 20,
          color: Colors.textPrimaryColor,
        },
      },
    },
    "& .img-wrap": {
      "& img": {
        maxWidth: 900,
        width: "100%",
      },
      "&.right": {
        right: 0,
        top: 0,
        "& img": {
          float: "right",
        },
      },
      "&.left": {
        left: 0,
        top: 0,
        "& img": {
          float: "left",
        },
      },
    },
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
  mainlanderPageText: {
    position: "relative",
    marginTop: 0,
    "& .text-block": {
      padding: [50, 20, 50, 115],
      "@media screen and (max-width: 1680px)": {
        padding: "0px 0px 50px !important",
      },
    },
    "& .scroll-to": {
      fontSize: 30,
      color: Colors.themePrimayColor,
    },
    "& h1": {
      fontWeight: 700,
      fontSize: 40,
      maxWidth: 700,
      marginBotton: 30,
      "@media screen and (max-width: 680px)": {
        fontSize: 30,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& .landingPageHeading": {
      fontWeight: 700,
      fontSize: 16,
      maxWidth: 700,
      marginBotton: 30,
      display: "flex",
      justifyContent: "center",
      "@media screen and (max-width: 680px)": {
        fontSize: 12,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& p": {
      fontSize: 24,
      marginTop: 20,
    },
    "& strong": {
      display: "block",
      fontSize: 24,
      fontWeight: 500,
      marginTop: 30,
    },
    "& ul": {
      paddingLeft: 20,
      margin: [30, 0],
      lineHeight: 1,
      fontFamily: Global.fontSecondary,
      "@media screen and (max-width: 1200px)": {
        marginTop: "30px !important",
        marginBottom: "30px !important",
      },
      "& h6": {
        fontWeight: 400,
        fontSize: 15,
        marginBotton: 30,
        "@media screen and (max-width: 680px)": {
          fontSize: 13,
        },
      },
      "& li": {
        fontSize: 15,
        marginBottom: 30,
        fontWeight: 400,
        lineHeight: 1.25,
        "@media screen and (max-width: 1200px)": {
          fontSize: 13,
        },
        // "&::marker ": {
        //   fontSize: 20,
        //   color: Colors.textPrimaryColor,
        // },
      },
    },
    "& .img-wrap": {
      "& img": {
        maxWidth: 900,
        width: "100%",
      },
      "&.right": {
        right: 0,
        top: 0,
        "& img": {
          float: "right",
        },
      },
      "&.left": {
        left: 0,
        top: 0,
        "& img": {
          float: "left",
        },
      },
    },
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
  aboutusPageText: {
    position: "relative",
    marginTop: 0,
    "& .text-block": {
      padding: [50, 20, 50, 115],
      "@media screen and (max-width: 1680px)": {
        padding: "0px 0px 50px !important",
      },
    },
    "& .scroll-to": {
      fontSize: 30,
      color: Colors.themePrimayColor,
    },
    "& h1": {
      fontWeight: 700,
      fontSize: 40,
      maxWidth: 700,
      marginBotton: 30,
      "@media screen and (max-width: 680px)": {
        fontSize: 30,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& .landingPageHeading": {
      fontWeight: 700,
      fontSize: 16,
      maxWidth: 700,
      marginBotton: 30,
      display: "flex",
      justifyContent: "center",
      "@media screen and (max-width: 680px)": {
        fontSize: 12,
      },
      "& span": {
        color: Colors.themePrimayColor,
      },
    },
    "& p": {
      fontSize: 24,
      marginTop: 20,
    },
    "& strong": {
      display: "block",
      fontSize: 24,
      fontWeight: 500,
      marginTop: 30,
    },
    "& ul": {
      paddingLeft: 20,
      margin: [15, 0],
      lineHeight: 1,
      fontFamily: Global.fontSecondary,
      "@media screen and (max-width: 1200px)": {
        marginTop: "30px !important",
        marginBottom: "30px !important",
      },
      "& li": {
        fontSize: 15,
        marginBottom: 10,
        fontWeight: 400,
        lineHeight: 1.25,
        "@media screen and (max-width: 1200px)": {
          fontSize: 13,
        },
        "&::marker ": {
          fontSize: 20,
          color: Colors.textPrimaryColor,
        },
      },
    },
    "& .img-wrap": {
      "& img": {
        maxWidth: 900,
        width: "100%",
      },
      "&.right": {
        right: 0,
        top: 0,
        "& img": {
          float: "right",
        },
      },
      "&.left": {
        left: 0,
        top: 0,
        "& img": {
          float: "left",
        },
      },
    },
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
  margin: {
    marginTop: -50,
    },
    mobileReposive:{
      paddingLeft:"46px",
      "@media screen and (max-width: 760px)": {
        padding: "18px",
      },
    }
});
