import { useStyles } from "./styles";
import { useStyles as styles } from "../../uiCore/styles";
import ImgCompleteQuiz from "../../assets/images/img-complete-quiz.png";
import { Button } from "../../components";
import { useHistory, useParams } from "react-router";

const CompleteQuizPage = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const classes = useStyles();
  const globalStyles = styles();
  return (
    <div className="start-quiz-wrapper">
      <section className={`${globalStyles.section} ${globalStyles.secTextImg}`}>
        <div className="row align-items-center mx-0">
          <div className="col-lg-6">
            <div className="text-block">
              <h1>Thank you for doing the quiz.</h1>
              <p>
                You will receive an email within the next 24 hours with feedback
                and a percentile grade.
              </p>

              <Button
                variant="primary"
                text="Go Back to Hompage"
                className={"text-center"}
                onClick={() => history.push("/welcome")}
              />
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className={`${classes.imgWrap} right`}>
              <img src={ImgCompleteQuiz} alt="Header Img" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompleteQuizPage;
