import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import { useStyles as styles } from "../../uiCore/styles";
import { useStyles } from "./styles";

const CartItems = ({ cartItems }: any) => {
  const globalStyles = styles();
  const classes = useStyles();

  const { user } = useSelector((state: any) => ({
    user: state.authentication.user,
  }));

  let totalPrice = 0;
  if (cartItems?.length) {
    totalPrice = cartItems?.reduce((a: any, b: { price: any }) => {
      return (totalPrice = b.price ? a + b.price : totalPrice);
    }, 0);
  }

  return (
    <section className={classes.cartItems}>
      <h1 className={globalStyles.title}>Your Cart</h1>
      <table className="table">
        <thead>
          <th>Item</th>
          <th className="w-100 text-end">Price</th>
        </thead>
        <tbody>
          {cartItems?.map((item: { allItemIds: any; quizNumber: number; name: string; price: boolean; index: number }) => {
            return (
              <tr>
                <td className="text-capitalize">{item?.allItemIds ? item?.name : `${item?.name} #${item?.quizNumber}`} </td>
                <td className="text-end" style={{ minWidth: "200px" }}>
                  CA$ {item?.price}
                </td>
              </tr>
            );
          })}
          <tr className="total text-end">
            <td colSpan={2}>Total: {`CA$${totalPrice}`}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default CartItems;
