import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as authActions from "../../redux/reducers/auth/authAction";
import {
  INVALID_PASSWORD,
  INVALID_PASSWORD_REGEX,
  PASSWORD_REGEX,
} from "../../config/validations";
import { Container } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useStyles } from "./styles";
import Logo from "../../assets/images/logo-light.svg";
import ArrowLight from "../../assets/images/arrow-light.svg";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import { Button, InputText } from "../../components";
import toast from "react-hot-toast";

const changePasswordInitialValues = {
  password: "",
  confirmPassword: "",
};

const ChangePasswordPage = () => {
  const classes = useStyles();
  const history = useHistory<any>();
  const dispatch = useDispatch<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { resetToken }: any = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: changePasswordInitialValues });

  const onSubmit = (data: any) => {
    if (data.password === data.confirmPassword) {
      dispatch(
        authActions.changePassword({
          password: data.confirmPassword,
          token: resetToken,
        })
      )
        .then((response: any) => {
          history.push("/auth/login");
        })
        .catch((error: any) => {
          error.clientMessage = "Can't find users";
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Password not match");
    }
  };

  return (
    <div className={classes.authWrapper}>
      <Container className={classes.container}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="text-block">
              <div className={classes.logoWrap}>
                <Link to="/" tabIndex={-1}>
                  <img src={Logo} alt="logo"></img>
                </Link>
              </div>
              <h1>Change your password</h1>

              <strong>
                Already have the password?{" "}
                <Link to="/auth/login" tabIndex={-1}>
                  Login{" "}
                </Link>
                <img src={ArrowLight} alt="arrow" />
              </strong>
            </div>
          </div>
          <div className="col-lg-8">
            <form
              className={classes.formWrap}
              onSubmit={handleSubmit(onSubmit)}
            >
              <h1 className={`${classes.title} left`}>Change Password</h1>
              <div className="row">
                <div className="col-lg-6">
                  <InputText
                    name={"password"}
                    type={showPassword ? "text" : "password"}
                    placeholder={"Password"}
                    postfix={showPassword ? <FaEye /> : <FaEye />}
                    postfixAction={() => {
                      setShowPassword(!showPassword);
                    }}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: `${INVALID_PASSWORD}`,
                      },
                      pattern: {
                        value: PASSWORD_REGEX,
                        message: INVALID_PASSWORD_REGEX,
                      },
                    }}
                    error={
                      errors["password"]?.message
                        ? errors["password"]?.message
                        : ""
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    name={"confirmPassword"}
                    type={showPassword ? "text" : "password"}
                    placeholder={"Confirm Password"}
                    postfix={showPassword ? <FaEye /> : <FaEye />}
                    postfixAction={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: `${INVALID_PASSWORD}`,
                      },
                      pattern: {
                        value: PASSWORD_REGEX,
                        message: INVALID_PASSWORD_REGEX,
                      },
                    }}
                    error={
                      errors["password"]?.message
                        ? errors["password"]?.message
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 mt-5">
                  <Button
                    type={"submit"}
                    className={"float-end"}
                    text="Submit"
                    postfix={<img src={ArrowDark} alt="arrow" />}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ChangePasswordPage;
