import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import * as quizActions from "../../redux/reducers/quizzes/quizzesAction";
import { Container, Modal } from "react-bootstrap";
import { Button, Pagination, QuizQuestionText } from "../../components";
import { useStyles as styles } from "../../uiCore/styles";
import { useStyles } from "./styles";
import toast from "react-hot-toast";
import { QuizPageLoader } from "./QuizPageLoader";

const questionInitialValues = {
  answer: "",
};

const QuizPage = () => {
  const { handleSubmit } = useForm({ defaultValues: questionInitialValues });
  const [loading, setLoading] = useState<any>(false);

  const { userQuiz } = useSelector((state: any) => ({
    user: state.authentication.user,
    userQuiz: state.quizzes.userQuizEntities,
  }));

  const { id, pageNumber }: any = useParams();
  const dispatch: any = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const globalStyles = styles();

  const [answerVal, setAnswerVal] = useState<any>("");
  const [answers, setAnswers] = useState<any>([]);
  const [confirm, setConfirm] = useState<any>(false);
  const [currPage, setCurrPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<string>("");
  const [attemptedQuestionsInd, setAttemptedQuestionInd] = useState<any>(pageNumber);

  useEffect(() => {
    if (userQuiz && userQuiz[pageNumber - 1]) {
      setTotalPages(userQuiz?.length);
      setAnswers([...userQuiz[pageNumber - 1].parts]);
    }
  }, [pageNumber, userQuiz]);

  useEffect(() => {
    if (id) {
      dispatch(quizActions.fetchUserQuiz(id));
    }
  }, [id]);

  useEffect(() => {
    if (userQuiz?.length) {
      let i = 0;
      userQuiz.forEach((it: any, index: any) => {
        if (it.attempted === true) {
          i = index + 1;
        }
      });
      history.push(`/quiz/${id}/${i !== Number(totalPages) ? i + 1 : i}`);
      setAttemptedQuestionInd(i);
    }
  }, [userQuiz]);

  const submitAnswers = (data: any) => {
    if (data) {
      setLoading(true);
      dispatch(
        quizActions.submitUserQuestion(id, {
          questionId: userQuiz[pageNumber - 1]._id,
          parts: [...answers],
        })
      )
        .then(() => {
          if (pageNumber === `${totalPages}`) {
            setConfirm(true);
          } else {
            dispatch(quizActions.fetchUserQuiz(id));
            if (pageNumber < attemptedQuestionsInd) {
              history.push(`/quiz/${id}/${attemptedQuestionsInd}`);
              setAttemptedQuestionInd(attemptedQuestionsInd + 1);
              setCurrPage(pageNumber + 1);
            } else {
              setCurrPage(pageNumber + 1);
              setAttemptedQuestionInd(attemptedQuestionsInd + 1);
              history.push(`/quiz/${id}/${pageNumber + 1}`);
            }
            setAnswerVal("");
          }
          setTimeout(() => {
            setLoading(false);
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
          }, 500);
        })
        .catch((error: any) => {
          error.clientMessage = "Can't find users";
          toast.error(error?.response?.data?.message);
        });
    }
  };
  const submitLastAnswer = () => {
    history.push(`/quiz/completed/${id}`);
    dispatch(quizActions.submitUserQuiz(id));
    setAnswerVal("");
  };

  return (
    <section className={`quiz-page ${globalStyles.section} ${classes.secQuiz}`}>
      {loading ? (
        <QuizPageLoader />
      ) : (
        <Container>
          <form autoComplete="false" onSubmit={handleSubmit(submitAnswers)}>
            <h2>
              Question {pageNumber}: {userQuiz?.length && userQuiz[pageNumber - 1]?.statement}
            </h2>
            <Pagination
              totalPages={totalPages}
              currPage={pageNumber}
              activePage={attemptedQuestionsInd === 0 ? pageNumber : attemptedQuestionsInd}
              onClick={(index: any) => {
                history.push(`/quiz/${id}/${index}`);
              }}
              onChange={(index: any) => setAttemptedQuestionInd(index)}
            />
            <QuizQuestionText
              questions={userQuiz && userQuiz[pageNumber - 1]?.parts}
              hasParts={true}
              answers={answers}
              setAnswers={setAnswers}
              answerVal={answerVal}
              setAnswerVal={setAnswerVal}
            />
            <Button
              type="submit"
              text={pageNumber === `${totalPages}` ? "Submit" : "Next Question/Submit"}
              className={"text-center"}
              variant="primary"
              // loading={loading}
            />
          </form>
          <Modal show={confirm} onHide={() => setConfirm(false)}>
            <Modal.Body>
              <h4 className="pt-4">Are you sure you ready to submit your quiz?</h4>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button text={"No"} variant="secondary" onClick={() => setConfirm(false)} />
              <Button text={"Yes"} variant="primary" type="submit" onClick={submitLastAnswer} />
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </section>
  );
};

export default QuizPage;
