import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as authActions from "../../redux/reducers/auth/authAction";
import {
  EMAIL_REGEX,
  INVALID_EMAIL,
  INVALID_PASSWORD,
  INVALID_PASSWORD_REGEX,
  PASSWORD_REGEX,
} from "../../config/validations";
import { Container } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useStyles } from "./styles";
import Logo from "../../assets/images/logo-light.svg";
import ArrowLight from "../../assets/images/arrow-light.svg";
import ArrowDark from "../../assets/images/arrow-dark.svg";
import { Button, InputText } from "../../components";
import toast from "react-hot-toast";

const loginInitialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: loginInitialValues });
  const classes = useStyles();
  const history = useHistory<any>();
  const dispatch = useDispatch<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<any>(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    dispatch(authActions.login(data))
      .then((response: any) => {
        history.push("/welcome");
        setLoading(false);
      })
      .catch((error: any) => {
        error.clientMessage = "Can't find users";
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className={classes.authWrapper}>
      <Container className={classes.container}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="text-block">
              <div className={classes.logoWrap}>
                <Link to="/" tabIndex={-1}>
                  <img src={Logo} alt="logo"></img>
                </Link>
              </div>
              <h1>Getting Start</h1>

              <strong>
                Don't have an account?{" "}
                <Link to="/auth/register" tabIndex={-1}>
                  Sign Up{" "}
                </Link>
                <img src={ArrowLight} alt="arrow" />
              </strong>
            </div>
          </div>
          <div className="col-lg-8">
            <form
              className={classes.formWrap}
              onSubmit={handleSubmit(onSubmit)}
            >
              <h1 className={`${classes.title} left`}>Login</h1>
              <div className="row">
                <div className="col-lg-6">
                  <InputText
                    name={"email"}
                    type={"email"}
                    placeholder={"Email"}
                    autoComplete="off"
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: INVALID_EMAIL,
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: INVALID_EMAIL,
                      },
                    }}
                    error={
                      errors["email"]?.message ? errors["email"]?.message : ""
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <InputText
                    name={"password"}
                    type={showPassword ? "text" : "password"}
                    placeholder={"Password"}
                    postfix={showPassword ? <FaEye /> : <FaEye />}
                    postfixAction={() => {
                      setShowPassword(!showPassword);
                    }}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: `${INVALID_PASSWORD}`,
                      },
                      pattern: {
                        value: PASSWORD_REGEX,
                        message: INVALID_PASSWORD_REGEX,
                      },
                    }}
                    error={
                      errors["password"]?.message
                        ? errors["password"]?.message
                        : ""
                    }
                  />
                  <Link to="/auth/forgot-password" tabIndex={-1}>
                    Forget Password?
                  </Link>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 mt-5">
                  <Button
                    type={"submit"}
                    className={"float-end"}
                    text="Login"
                    postfix={<img src={ArrowDark} alt="arrow" />}
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default LoginPage;
