import { createUseStyles } from "react-jss";
import { Colors, Global } from "../../uiCore/theme";

export const useStyles = createUseStyles({
  cardRedirectMessages: {
    boxShadow: Global.cardShadow,
    textAlign: "center",
    maxWidth: 300,
    padding: [20, 30],
    margin: "5rem auto",
    "& h1": {
      // color: Colors.themePrimayColor,
      fontSize: 24,
      fontFamily: Global.fontSecondary,
      lineHeight: 1.25,
      marginBottom: 50,
    },
  },
});
