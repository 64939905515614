import { Card } from "react-bootstrap";
import { useStyles } from "../cards/styles";
import { useSoosStyles } from "../soos/styles";
import IconCheckCircle from "../../assets/images/icon-check-circle.svg";

interface QuizItemProps {
  title?: string;
  price?: string;
  timeOfDay?: String;
  action?: string;
  className?: string;
  purchased?: boolean;
  attempted?: boolean;
  onClick?: any;
}

export const SoosItem = ({
  title = "",
  price = "",
  timeOfDay = "",
  action = "",
  className = "",
  onClick = () => {},
}: QuizItemProps) => {
  const classes = useStyles();
  const soosClasses = useSoosStyles();
  return (
    <Card className={`${classes.cardQuiz} ${className}`}>
      <h2>{title}</h2>
      <h4>{timeOfDay}</h4>
      <div
        className={
          className
            ? `${soosClasses.priceSelectedLayout}`
            : `${soosClasses.priceLayout}`
        }
      >
        <span>CA$ {price}</span> <button onClick={onClick}>{action}</button>
      </div>
    </Card>
  );
};
