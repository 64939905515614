import { createUseStyles } from "react-jss";
import { Colors } from "../../../uiCore/theme";
import FooterBg from "../../../assets/images/bg-footer.svg";

export const useStyles = createUseStyles({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: `${Colors.themePrimayColor} url(${FooterBg}) center center no-repeat`,
    backgroundSize: "cover",
    padding: [50, 130],
    "@media screen and (max-width: 1200px)": {
      padding: [50, 16],
      flexWrap: "wrap",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: 60,
    },
    "& p": {
      color: "#ffffff",
      fontSize: 18,
      margin: [0, 20],
      borderLeftWidth: "1px",
      borderStyle: "solid",
      borderColor: "white",
      border: 0,
      padding: [10, 20],
      marginLeft: 70,
    },
    "@media screen and (max-width: 991px)": {
      margin: "0 auto",
      flexDirection: "column",
      "& p": {
        margin: "1rem auto",
        borderTopWidth: "1px",
        borderLeftWidth: "0",
      },
    },
  },
  footer_links: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    "& li": {
      color: "#ffffff",
      fontSize: 18,
      width: "max-content",
      "& a": {
        color: "#ffffff",
        marginLeft: 70,
      },
    },
    "@media screen and (max-width: 991px)": {
      margin: "2rem auto",
      justifyContent: "space-between",
      width: "100%",
      "& li": {
        "& a": {
          margin: "0 auto !important",
        },
      },
    },
  },
  footer_social: {
    display: "flex",
    alignItems: "center",
    margin: [0, 70],
    padding: [10, 20],
    "& li": {
      marginLeft: 20,
    },
    "@media screen and (max-width: 1200px)": {
      margin: "0 auto",
    },
  },
});
