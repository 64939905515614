import React from "react";
import { useStyles } from "./styles";

interface FbuttonProps {
  text?: String;
  variant?: String;
  align?: String;
  className?: String;
  tabIndex?: any;
  onClick?: any;
  prefix?: any;
  postfix?: any;
  style?: any;
  type?: any;
  disabled?: boolean;
  loading?: boolean;
}
export const Button = ({
  text,
  variant = "",
  align = "",
  className = "",
  onClick = () => {},
  prefix,
  postfix,
  style,
  type = "button",
  disabled = false,
  loading = false,
}: FbuttonProps) => {
  const classes = useStyles();
  return (
    <>
      <button
        className={`${classes.buttonDefautStyle} ${
          variant === "primary"
            ? classes.buttonPrimary
            : variant === "secondary"
            ? classes.buttonSecondary
            : ""
        } ${postfix ? "pl-1" : ""} ${
          prefix ? "pr-1" : ""
        } text-${align} ${className}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {prefix && <span className="btn-icon prefix">{prefix}</span>}
        <span className="btn-text">{text}</span>
        {/* {postfix && <span className="btn-icon postfix">{postfix}</span>} */}

        {postfix || loading ? (
          loading ? (
            <div className="btn-icon postfix spinner-grow">
              <span className="sr-only">Loading</span>
            </div>
          ) : (
            <span className="btn-icon postfix">{postfix}</span>
          )
        ) : null}
      </button>
    </>
  );
};
