import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as authActions from "../../../redux/reducers/auth/authAction";
import { useStyles } from "./styles";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Button } from "../..";
import Logo from "../../../assets/images/logo-dark.svg";
import Readylogo from "../../../assets/images/ccfpready-logo.jpg"
import IconUserDark from "../../../assets/images/icon-user-dark.svg";
import IconUserLight from "../../../assets/images/icon-user-light.svg";
import IconUserPlus from "../../../assets/images/icon-user-plus-light.svg";
import IconCart from "../../../assets/images/icon-cart-dark.svg";
import { FaSignOutAlt } from "react-icons/fa";
import { setTimeout } from "timers/promises";

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, cartItems } = useSelector((state: any) => ({
    user: state.authentication.user,
    cartItems: state.quizzes.cartEntities,
  }));
  return (
    <header className={classes.header}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand
            className={classes.logo}
            as={Link}
            to={user ? "/welcome" : "/"}
          >
            <img src={Readylogo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse className="justify-content-end">
            <Nav className="align-items-center">
            <Link className={classes.link} to="/blogs">
                Blogs
              </Link>
              <Link className={classes.link} to="/about">
                About Us
              </Link>
              <Link className={classes.link} to="/contact">
                Contact
              </Link>
              {!user ? (
                <>
                  <Button
                    onClick={() => history.push("/auth/login")}
                    className={"mx-3"}
                    variant={"secondary"}
                    text="Login"
                    prefix={<img src={IconUserDark} alt="Login" />}
                  />
                  <Button
                    onClick={() => {
                      history.push("/auth/register");
                      let scrollTo: number;
                      scrollTo = window.setTimeout(() => {
                        let el: any = document.getElementById("quizzes");
                        el.scrollIntoView();
                      }, 100);
                      return scrollTo;
                    }}
                    variant={"primary"}
                    text="Register Now"
                    prefix={<img src={IconUserPlus} alt="Register" />}
                  />
                </>
              ) : (
                <Dropdown
                  className={classes.userDropdown}
                  drop="down"
                  alignRight
                >
                  <Dropdown.Toggle
                    variant="primary"
                    id="userDropdown"
                    className="d-flex align-items-center px-2 py-3"
                  >
                    <img src={IconUserLight} alt="Icon" className="ml-2" />
                    <span className="welcome-text">
                      Hey, {user?.first_name} {user?.last_name}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        if (user) {
                          dispatch(authActions.logout());
                          history.push("/");
                        }
                      }}
                    >
                      <div className="item-icon mr-2">
                        <FaSignOutAlt />
                      </div>
                      <div className="item-text">
                        <div className="font-weight-bold">Logout</div>
                      </div>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Link
                className={`${classes.link} pr-0`}
                to={cartItems && cartItems?.length ? "/cart" : "#"}
              >
                <img src={IconCart} alt="Your Cart" />
                <span className="counter">
                  {cartItems?.length ? cartItems?.length : 0}
                </span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
