import { createUseStyles } from "react-jss";
import { Colors } from "../../uiCore/theme";
import LoginBg from "../../assets/images/bg-login.png";

// VARIABLES
let logoWidth = 225;
export const useStyles = createUseStyles({
  container: {
    position: "relative",
    zIndex: "1000",
  },
  title: {
    fontSize: 42,
    position: "relative",
    paddingBottom: 10,
    marginBottom: 50,
    letterSpacing: 2,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: 50,
      height: 3,
      background: Colors.themePrimayColor,
    },
  },
  authWrapper: {
    background: `url(${LoginBg}) center center no-repeat`,
    backgroundSize: "100%",
    width: "100%",
    height: "100vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    "&:after": {
      content: '""',
      width: "100%",
      height: "100vh",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 100,
      background: "rgba(0,0,0, .65)",
    },
    "& .text-block": {
      color: "white",
      padding: [30, 0],
      "& h1": {
        marginTop: 30,
        marginBottom: 30,
        letterSpacing: 2,
        //   fontSize: 24,
      },
      "& p": {
        marginBottom: 30,
        fontSize: 20,
        maxWidth: 300,
        color: "rgba(255,255,255, .5)",
        lineHeight: 1.5,
      },
      "& strong": {
        fontSize: 24,
        fontWeight: 500,
        "& a": {
          color: "white",
          marginLeft: 10,
        },
        "& img": {
          marginLeft: 10,
        },
      },
    },
  },

  logoWrap: {
    position: "relative",
    paddingBottom: 24,
    "& img": {
      maxWidth: logoWidth,
      width: "100%",
    },
    "&:after": {
      content: '""',
      maxWidth: logoWidth + 75,
      width: "100%",
      background: "rgba(255,255,255, .5)",
      position: "absolute",
      bottom: 0,
      left: 0,
      height: 2,
    },
  },
  formWrap: {
    background: "white",
    padding: [75, 75],
    "& a": {
      color: Colors.themePrimayColor,
      textDecoration: "none",
      float: "right",
      marginTop: 10,
    },
  },
  registerForm: {
    "& .form-check": {
      padding: [20, 25],
      "& .form-check-input": {
        width: "1.25em",
        height: "1.25em",
        borderRadius: 0,
        "&:checked": {
          backgroundColor: Colors.themePrimayColor,
          borderColor: Colors.themePrimayColor,
        },
      },
      "& label": {
        fontSize: "1.25rem",
        lineHeight: 1.5,
        marginLeft: ".5rem",
        "& a": {
          color: Colors.themePrimayColor,
          marginLeft: 5,
        },
      },
    },
  },
});
